import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import './services.scss';


const Services = (props) => {
    const { socket } = props;
    useEffect(() => {
        const id = JSON.parse(localStorage.getItem("user"))._id || JSON.parse(localStorage.getItem("user")).id;
        socket.socket.emit('online', id);
    }, [socket])
    
    return  <Container className='d-flex h-100 align-items-center'>
                <Container className='services-page w-75 p-5'>
                    <Row className='my-4'>
                    <Col classNameName='p-5'>
                        <Link to="/home">
                        <button className="service-btn w-100">
                            <div className="align-items-center">
                            <div><Image src={require('../../assets/Menu-Create.svg').default}/></div>
                            <div className="label-text">HOME</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    <Col classNameName='p-5'>
                        <Link to="/feed">
                        <button className="service-btn w-100">
                            <div className="align-items-center">
                            <div><Image src={require("../../assets/FeedIcon.svg").default}/></div>
                            <div className="label-text">FEED</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    <Col classNameName='p-5'>
                        <Link to="/map">
                        <button className="service-btn w-100">
                            <div className="align-items-center">
                            <div><Image src={require("../../assets/Menu-Map.svg").default}/></div>
                            <div className="label-text">MAP</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    </Row>
                    <Row className='my-4'>
                    <Col classNameName='p-5'>
                        <Link to="/friends">
                        <button className="service-btn w-100">
                        <div className="align-items-center">
                            <div><Image src={require("../../assets/Menu-Friends.svg").default}/></div>
                            <div className="label-text">FRIENDS</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    <Col classNameName='p-5'>
                        <Link to="/content">
                        <button className="service-btn w-100">
                        <div className="align-items-center">
                            <div><Image src={require("../../assets/Menu-Play.svg").default}/></div>
                            <div className="label-text">CONTENT</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    <Col classNameName='p-5'>
                        <Link to="/event">
                        <button className="service-btn w-100">
                        <div className="align-items-center">
                            <div><Image src={require("../../assets/EventIcon.svg").default}/></div>
                            <div className="label-text">EVENT</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    </Row>
                    <Row className='my-4'>
                    <Col classNameName='p-5'>
                        <Link to="/create">
                        <button className="service-btn w-100">
                        <div className="align-items-center">
                            <div><Image src={require("../../assets/Menu-Create.svg").default}/></div>
                            <div className="label-text">CREATE</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    <Col classNameName='p-5'>
                        <Link to="/payment">
                        <button className="service-btn w-100">
                        <div className="align-items-center">
                            <div><Image src={require("../../assets/Menu-Market.svg").default}/></div>
                            <div className="label-text">MARKET</div>
                            </div>
                        </button>
                        </Link>
                    </Col>
                    <Col classNameName='p-5'>
                        <Link to="/wallet">
                        <button className="service-btn w-100">
                        <div className="align-items-center">
                            <div><Image src={require("../../assets/Menu-Wallet.svg").default}/></div>
                            <div className="label-text">WALLET</div>
                            </div>  
                        </button>
                        </Link>
                    </Col>
                    </Row>
                </Container>
            </Container>
}


export default Services