import { createSlice,current  } from "@reduxjs/toolkit";
import {axiosInstance} from '../services';

export const friendsSlice = createSlice({
  name: "friends",
  initialState: {
    friendsData: [],
    isLoading: false,
    error: '',
    userFriendRequests: [],
    isBlocked: false,
  },
  reducers: {
    setLoading: (state, action) => {
      console.log(current(state));
      state.isLoading = action.payload
      state.friendsData = []
      state.error = ''
    },
    
    hasError: (state, action) => {
      state.error = action.payload
      state.isLoading = false
      state.roles = []
    },
    getFriends: (state, action) => {
      state.friendsData = action.payload
      state.error = ''
      state.isLoading = false
    },
    getUserFriendReq: (state, action) => {
      state.userFriendRequests = action.payload
      state.error = ''
      state.isLoading = false
    },
    updateFriend: (state, action) => {
      state.error = ''
      state.isLoading = false
      state.sucessMessage = action.payload
    },
    setBlocked: (state, action) => {
      state.isBlocked = action.payload
    }
  },
});

export const { getFriends, getUserFriendReq, updateFriend, setLoading, hasError, setBlocked } = friendsSlice.actions;

export const getFriendsData = (userId) => async dispatch => {
   dispatch(setLoading(true));
    try {
      await axiosInstance.get(`/friends/getFriends/${userId}`)
        .then((response) => {
          dispatch(setLoading(false));
          if(response.data.code === '401')
            return dispatch(hasError(response.data.msg))

          return dispatch(getFriends(response.data));

        })
    } catch (e) {
     return console.error(e.message);
    }
};

export const getUserFriendsRequestData = (userId) => async dispatch => {
  dispatch(setLoading(true));
   try {
     await axiosInstance.post(`/friends/getUserFriendReq1/`, {id: userId})
       .then((response) => {
         dispatch(setLoading(false));
         if(response.data.code === '401')
           return dispatch(hasError(response.data.msg))

         return dispatch(getUserFriendReq(response.data));

       })
   } catch (e) {
    return console.error(e.message);
   }
};

export const updateFriendReqStatus = (friend) => async dispatch => {
  dispatch(setLoading(true));
   try {
     await axiosInstance.post(`/friends/updateFriendReqStatus/`, friend)
       .then((response) => {
         dispatch(setLoading(false));
         if(response.data.code === '401')
           return dispatch(hasError(response.data.msg))

         return dispatch(updateFriend('Updated successfully'));

       })
   } catch (e) {
    return console.error(e.message);
   }
};

export const setBlockedStatus = (data) => async dispatch => {
  dispatch(setLoading(true));
   try {
     await axiosInstance.post("/blocks/isBlock/",data)
       .then((response) => {
        debugger
           return dispatch(hasError(response.data))
       })
   } catch (e) {
    return console.error(e.message);
   }
};
export const friends = (data) => {
  return axiosInstance.post("/friends/friend/", data);
}

export const isFriendReqSent = (data) => {
  return axiosInstance.post("/friends/isFriendReqSent", data);
}

export async function isBlocked(data) {
  return axiosInstance.post("/blocks/isBlock/",data);
}

export async function isAccepted(data) {
  return axiosInstance.post("/chats/isAccepted", data);
}

export async function sendRequest(data) {
  return axiosInstance.post("/chats/request", data);
}

export async function getRequests(data) {
  return axiosInstance.post("/chats/getRequest", data);
}
export async function getpartnerReq(data) {
  return axiosInstance.post("/chats/getpartnerRequest", data);
}

export async function acceptPartnerChatReq(data) {
  return axiosInstance.post("/chats/acceptParterChatRequest", data);
}

export async function acceptChat(data) {
  return axiosInstance.post("/chats/accept/", data);
}

export async function rejectChat(data) {
  return axiosInstance.post("/chats/reject/", data);
}



export async function friendrequest(data) {
  return axiosInstance.post("/friends/addfriend", data);
}

export async function updateAddFriend(data) {
  return axiosInstance.post("/users/updateAddFriend", data);
}

export default friendsSlice.reducer;

