import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faComment } from "@fortawesome/free-solid-svg-icons";

const PostSection = (props) => {
  const { post } = props;
  const { user } = post;
  return (
    <Container style={{ borderRadius: "0.5rem", backgroundColor: "#121212", padding: "1.5rem", marginBottom: "2rem" }}>
      <Row className="mb-3">
        <Col xs={1}>
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <Image
              src={user.profile}
              roundedCircle
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Col>
        <Col xs={11}>
          <Row>
            <Col style={{ textAlign: "left", marginLeft: "2rem"}}>
              <h5>{user.username}</h5>
              <p style={{ opacity: "0.7"}}>14mins ago</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div>
            {post?.images.map((img) => {
              return <Image src={img} key={img} alt={"post"} style={{ width: "100%", objectFit: "cover"}} />;
            })}
          </div>
          <div style={{textAlign: "left", opacity: "0.7"}} dangerouslySetInnerHTML={{ __html: post.description }}></div>
          {/* <Image src={post.description} fluid /> */}
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FontAwesomeIcon icon={faHeart} /> {post.likes.length} Likes
        </Col>
        <Col xs={6}>
          <FontAwesomeIcon icon={faComment} /> {post.comment.length} Comments
        </Col>
      </Row>
    </Container>
  );
};

export default PostSection;
