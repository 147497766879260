import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import {
  CountryDropdown,
  RegionDropdown,
} from "react-country-region-selector";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./register.scss";
import { registerUserData } from "../../toolkit/usersSlice";
import { successMessage, warningMessage } from "../../toolkit/toastSlice";

export const Register = () => {
  const animatedComponents = makeAnimated();
  const roles = useSelector((state) => state.roles.roles);
  const isLoading = useSelector((state) => state.user.isLoading);
  const userError = useSelector((state) => state.user.userError);
  const registerSuccessMessage = useSelector((state) => state.user.registerSuccessMessage);

  const [rolesData, setRolesData] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  useEffect(() => {
    if (roles.length) {
      setRolesData(
        roles.map((r) => {
          return { value: r.name, label: r.name };
        })
      );
    }
  }, [roles]);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ defaultValues: {} });
  const history = useNavigate();

  const registerUser = (user) => {
    dispatch(registerUserData(user));
  };

  useEffect(() => {
    if(userError)
      dispatch(warningMessage(userError))
  }, [userError])

  useEffect(() => {
    if (registerSuccessMessage === "Thank you for registration. Please login by clicking on the link send on your registered email" ) {
      dispatch(successMessage(registerSuccessMessage))
      history(`/login`)
    }
  }, [registerSuccessMessage])

  return (
    <div className="register-page w-100 m-0">
      <Row className="d-flex text-start m-2">
        <Col>
          <Image
            src={require("../../assets/shockwaveIcon.svg").default}
            className="logo-img"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Container className="px-5 mx-md-5 fields-container">
          <Row>
            <Col>
              <h1 className="white">Register</h1>
            </Col>
          </Row>
          <Row className="px-md-5 justify-content-center">
            <Col sm={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="First Name"
                id="firstname"
                required
                className="box-line"
                {...register("firstname", { required: true })}
              />
              {errors.firstname && (
                <span className="error">{'Firstname is Required'}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="Last Name"
                id="lastname"
                required
                className="box-line"
                {...register("lastname", { required: true })}
              />
              {errors.lastname && (
                <span className="error">{'Lastname is required'}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="email"
                placeholder="Email Address"
                id="email"
                required
                className="box-line"
                {...register("email", {
                  required: "Email is required",
                  validate: {
                    matchPattern: (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      "Email address must be a valid address",
                  },
                })}
              />
              {errors.email && (
                <span className="error">{errors.email.message}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="Username"
                id="username"
                required
                className="box-line"
                {...register("username", { required: true })}
              />
              {errors.username && (
                <span className="error">{'Username is required'}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="password"
                placeholder="Password"
                id="password"
                required
                className="box-line"
                {...register("password", {
                  required: "password is required",
                  validate: {
                    matchPattern: (v) =>
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) ||
                      "Match the passwrod pattern",
                  },
                })}
              />
              {errors.password && (
                <span className="error">{errors.password.message}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                id="confirmpassword"
                required
                className="box-line"
                {...register("passwordConf", {
                  required: true,
                  validate: (val) => {
                    if (watch("password") !== val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
              />
              {errors.passwordConf && (
                <span className="error">{errors.passwordConf.message}</span>
              )}
              <span>
                Please use One capital letter and one special character in your
                password
              </span>
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={rolesData || []}
                {...register("role", { required: false })}
                onChange={(e) => {
                    const permittedValues = e.map(function(record) {
                      return record.value;
                    });
                    setValue("role", JSON.stringify(permittedValues)
                    )}}
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="Type exactly what you are"
                id="firstname"
                required
                className="box-line"
                {...register("customRole", { required: false })}
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <CountryDropdown
                value={country}
                countryValueType="short"
                onChange={(value) => {setValue("country", value); setCountry(value)}}
                className="box-line form-control"
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <RegionDropdown
                country={country}
                value={region}
                onChange={(value) => {setValue("region", value); setRegion(value)}}
                className="box-line form-control"
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Check
                inline
                type="checkbox"
                id="agree"
                className="white"
                label="I agree to the website terms & conditions"
                {...register("agree", { required: true })}
              />
              {errors.agree && <span>You must need to check this.</span>}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <input
                disabled={isLoading}
                type="submit"
                value="Register"
                className="signInBtn"
                onClick={handleSubmit(registerUser)}
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};
