const firebaseConfig = {
	apiKey: "AIzaSyADj59gR5NnL6Y6hPUQwGuOqs36T0g6o1c",
	authDomain: "chatapp-1c56b.firebaseapp.com",
	databaseURL:
		"https://chatapp-1c56b-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "chatapp-1c56b",
	storageBucket: "chatapp-1c56b.appspot.com",
	messagingSenderId: "257179533883",
	appId: "1:257179533883:web:543ea75f46a6a3589ad21e",
};

export default firebaseConfig;
