import React from "react";
import "./BottomBar.scss"; // Import your CSS file
import { Image } from "react-bootstrap";

const BottomBar = (props) => {
  const {setShowEmoji, showEmoji, fileRef, postFiles, renderImages, gifRef, handleSubmit} = props;
  return (
    <div className="custom-bar">
      <div className="left-icons">
        <button
          type="button"
          className="emoji-button "
          onClick={() => gifRef.current.click()}
        >
          {" "}
          <Image src={require("../../assets/icons/gif.svg").default} />
        </button>
        <button
          type="button"
          className="emoji-button "
          onClick={() => fileRef.current.click()}
        >
          <Image src={require("../../assets/icons/image.svg").default} />
        </button>
        <button
          type="button"
          className="emoji-button "
          onClick={() => setShowEmoji(!showEmoji)}
        >
          <Image src={require("../../assets/icons/smile.svg").default} />
        </button>
        {postFiles.length > 0 && <div>{renderImages}</div>}

      </div>
      <button className="post-button" onClick={(e) => handleSubmit(e)}>Post</button>
    </div>
  );
};

export default BottomBar;
