import React from 'react';
import {Link} from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';


import './home.scss';

export const Home = () => {
    return <>
    <Container className='home-page'>
        <Row className='logo-row'>
            <Col>
                <Image src={require('../../assets/Logo-new.svg').default} className='logo-img'/>
            </Col>
        </Row>
        <Row>
            <Col><Link to="/login"><input type="button" value="Sign In"  className='sign-in-btn'/></Link></Col>
        </Row>
        <Row>
            <Col className='my-4'><Link to="/register" className='white-text'>Register Now</Link></Col>
        </Row>
    </Container>
    </>
}