import { useState, useRef, useEffect } from "react";
import { getXummData, connectAccount, deleteAccount } from "../../toolkit/NFTSlice";
import socketIO from 'socket.io-client';
import "./wallet.css";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Form, Image } from "react-bootstrap";

import {Xumm} from 'xumm'
const xumm = new Xumm('0cf498fd-1bd7-424a-b5ab-ca7f486a8f8f', '51e07589-c3b0-479a-9cfc-1aacd210134d') // Some API Key
xumm.on("ready", (data) => console.log(data, "Ready xumm (e.g. hide loading state of xApp)"))
xumm.on("retrieved", (data) => console.log(data, "retrieved ---- Ready xumm (e.g. hide loading state of xApp)"))
xumm.on("success", (data) => console.log(data, "success ---- Ready xumm (e.g. hide loading state of xApp)"))

xumm.on("error", (data) => console.log(data, "error ---- Ready xumm (e.g. hide loading state of xApp)"))

xumm.on('qr', data => {
    console.log('QR', data)
})

console.log(xumm, 'xumm')


export const Wallet = () => {
  const dispatch = useDispatch();
  const [connectionStatus, setConnectionStatus] = useState('disconnected')
  const [loading, setLoading] = useState('true')
  const [qrCode, setQrCode] = useState('')
  const [address, setAddress] = useState('')
  const [balance, setBalance] = useState('')
  const [uuid, setuuid] = useState('')
  const [id, setId] = useState(JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id)
  const socketRef = useRef()

  const socket = socketIO("https://shockwave.network:443", {transports: ['websocket']});


  const [mysocket, setMySocket] = useState(null);
  const [message, setMessage] = useState("");
  
  useEffect(() => {
    (async function() {
      
      socket.emit('update_xumm_tip', id)
      let response = await getXummData(id)
      if (response.data._id) {
        setConnectionStatus("connected")
        setAddress(response.data.wallet_id)
        setBalance(response.data.balance);
      }
      setLoading(false)
    })()
  }, [])

  socket.on("connect", (socket) => {
//      getUpdate(socket, response.data.uuid);
console.log("getUppdate11111");
    });

    socket.on("xumm", (msg) => {
console.log("from xumm")
      if (msg.message === "connected") {
        setConnectionStatus("connected")
        setAddress(msg.wallet_id)
        socket.disconnect();
        // this.showToast({
        //   title: "Xumm wallet connected!",
        //   type: "success",
        // });
      } else if (msg.message === "disconnected") {
        setConnectionStatus("disconnected")
        socket.disconnect();
        // this.showToast({
        //   title: "Request Rejected",
        //   type: "error",
        // });
      }
    });
  const getDeviceLoginLink = () => {
    return `xumm://xumm.app/sign/${uuid}/deeplink`;
  }
  const getUpdate = async (sock, uuid) =>{
console.log("getuppdate call to emit.")
    socket.emit("xumm", { uuid });
    if (socket.connected) {
      setTimeout(getUpdate, 2000, sock, uuid);
    }
  }
  const connect = async () => {
    setConnectionStatus("connecting")
    let response = await connectAccount(id);
    debugger
    setQrCode(response.data?.refs?.qr_png)
    setuuid(response.data?.uuid)
   
 socket.emit("xumm", { uuid: response.data?.uuid });

    socket.on("update_xumm_tip", (socket) => {
      getUpdate(socket, response.data.uuid);
    });
    socket.on("connect", (socket) => {
console.log("getUppdate");

      getUpdate(socket, response.data.uuid);
console.log("getUppdate");
    });
    socket.on("xumm", (msg) => {
      if (msg.message === "connected") {
        setConnectionStatus("connected")
        setAddress(msg.wallet_id)
        socket.disconnect();
        // this.showToast({
        //   title: "Xumm wallet connected!",
        //   type: "success",
        // });
      } else if (msg.message === "disconnected") {
        setConnectionStatus("disconnected")
        socket.disconnect();
        // this.showToast({
        //   title: "Request Rejected",
        //   type: "error",
        // });
      }
    });
  }
  const disconnect = async () => {
    setConnectionStatus("disconnected")
    setQrCode("");
    await deleteAccount(id)
  }
  return (
    <div className="main-content">
    <div className="whitetext" style={{height: '100vh'}}>
      {
        connectionStatus === 'disconnected' && <div>
        <button onClick={() => connect()} classname="p-5 xumm-btn" style={{'marginTop': '350px'}}>
          <Image
            src={require("../../assets/xummSignIn.svg").default}
            className="xumm-icon"
          />
        </button>
      </div>
      }
     {
      connectionStatus === 'connecting' &&  <div>
        { qrCode && <img src={qrCode} alt="" style={{marginTop: '8vh'}} /> }
        <br />
        <p classname="mt-4">Connecting...</p>
        <p>To log into your current device</p>
        <a href={getDeviceLoginLink()}>click here</a>
      </div>
     }
     {
      connectionStatus === 'connected' &&  
      <div>
        <button onClick={disconnect()} classname="btn btn-primary disconnectBtn">
          Disconnect wallet
        </button>
        <div classname="wrapper">
          <div classname="row">
            <div classname="col-md-4 col-sm-12">
              <button classname="service-btn w-100">
              <div classname="align-items-center">
                  <div>
                  <Image
                    src={require("../../assets/Menu-Wallet.svg").default}
                    className="logo-img"
                  />
                  </div>
                  <div classname="label-text">WALLET</div>
                </div>  
            </button>
            </div>
            <div classname="col-md-8 col-sm-12">
              <div classname="container wallet-info mt-4">
                <div classname="row text-left">
                  <div classname="col-md-4">CX1 Balance</div>
                  <div classname="col-md-8">{{ balance }} &nbsp; CX1</div>
                </div>
                <div classname="row text-left">
                  <div classname="col-md-4">WAVE Balance</div>
                  <div classname="col-md-8">{{ balance }} &nbsp; WAVE</div>
                </div>
                <div classname="row text-left">
                  <div classname="col-md-4">Wallet ID</div>
                  <div classname="col-md-8">rpF7w6NYZtvJWvBYYEuNFZ4iLR7mR1MkS9</div>
                </div>
              </div>
            </div>
          </div>
          
         
        </div>
      </div>
    }
    </div>
  </div>
  );
};
