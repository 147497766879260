import { createSlice, current } from "@reduxjs/toolkit";
import { axiosInstance } from "../services";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loginUser: {},
    isLoading: false,
    error: "",
    registerUser: {},
    registerSuccessMessage: "",
    forgotPasswordSuccessMessage: "",
    resetPasswordSuccessMessage: "",
    profileData: {},
    editUser: {},
    miniProfile: [],
  },
  reducers: {
    setLoading: (state, action) => {
      console.log(current(state));
      state.isLoading = action.payload;
      state.loginUser = {};
      state.error = "";
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.loginUser = {};
    },
    registerUser: (state, action) => {
      state.registerUser = action.payload.data;
      state.registerSuccessMessage = action.payload.message;
      state.error = "";
      state.isLoading = false;
    },

    loginUser: (state, action) => {
      state.loginUser = action.payload;
      state.error = "";
      state.isLoading = false;
    },

    forgotPassword: (state, action) => {
      state.forgotPasswordSuccessMessage = action.payload.message;
      state.error = "";
      state.isLoading = false;
    },
    resetPassword: (state, action) => {
      state.resetPasswordSuccessMessage = action.payload.message;
      state.error = "";
      state.isLoading = false;
    },
    saveProfile: (state, action) => {
      state.updateProfileMessage = '';
      state.profileData = action.payload;
      state.error = "";
      state.isLoading = false;
    },
    updateProfileData: (state, action) => {
      state.profileData = action.payload;
      state.updateProfileMessage = 'Profile updated successfully!';
      state.error = "";
      state.isLoading = false;
    },
    getEditUser: (state, action) => {
      state.editUser = action.payload;
      state.error = "";
      state.isLoading = false;
    },
    getMiniProfile: (state, action) => {
      state.miniProfile = action.payload
      state.error = "";
      state.isLoading = false;
    }
  },
});

export async function updateProfilePicture(payload) {
  return axiosInstance.post('/users/uploadProfilePic/', payload)
}

export async function updateCoverPicture(payload) {
  return axiosInstance.post('/users/uploadCoverPic/', payload)
}

export const { loginUser, setLoading, hasError, registerUser, forgotPassword, resetPassword, saveProfile, getEditUser, updateProfileData,
  getMiniProfile } =
  userSlice.actions;

export const getLoginData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .post("/users/authenticate", { email: payload.email, password: payload.password, confirmationCode: payload.confirmationCode })
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "401")
          return dispatch(hasError(response.data.msg));
        if (response.data.code === "500")
          return dispatch(hasError(response.data.msg));

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('user', JSON.stringify(response.data));

        return dispatch(loginUser(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.response.data.msg));
  }
};

export const registerUserData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance.post("/users/register", payload).then((response) => {
      dispatch(setLoading(false));
      if (response.data.code === "500")
        return dispatch(hasError(response.data.msg));

      return dispatch(registerUser(response.data));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const forgotPasswordData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .post("/users/resetPassword", payload)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "500")
          return dispatch(hasError(response.data.msg));

        return dispatch(forgotPassword(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.response.data));
  }
};

export const resetPasswordData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .post("/users/confirmPassword", payload)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "500")
          return dispatch(hasError(response.data.msg));

        return dispatch(resetPassword(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.response.data));
  }
};

export const getProfile = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .get(`/users/${id}`)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "500")
          return dispatch(hasError(response.data.msg));

        return dispatch(saveProfile(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.response.data));
  }
};

export const getEditProfileData = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .get(`/users/${id}`)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "500")
          return dispatch(hasError(response.data.msg));

        return dispatch(getEditUser(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.response.data));
  }
};

export const updateProfile = (id, data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .put(`/users/edit/${id}`, {...data, role: JSON.stringify(data.role)})
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "500")
          return dispatch(hasError(response.data.msg));

        localStorage.setItem('user', JSON.stringify(data));
        return dispatch(updateProfileData(data));
      });
  } catch (e) {
    return dispatch(hasError(e.response.data));
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    await axiosInstance
      .get(`/users/${id}`)
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "401")
          return dispatch(hasError(response.data.msg));
        if (response.data.code === "500")
          return dispatch(hasError(response.data.msg));

        localStorage.setItem('user', JSON.stringify(response.data));

        return dispatch(loginUser(response.data));
      });
  } catch (e) {
    return dispatch(hasError(e.response.data.msg));
  }
};

export const hideOnMapData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .post("/users/hideOnMap", {id: payload})
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "401")
          return dispatch(hasError(response.data.msg));

          localStorage.setItem('user', JSON.stringify(response.data));
          return dispatch(loginUser(response.data));

      });
  } catch (e) {
    return console.error(e.message);
  }
}

export const getMiniProfileData = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .post("/users/getByIdCount", {id: id})
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "401")
          return dispatch(hasError(response.data.msg));

          return dispatch(getMiniProfile(response.data));

      });
  } catch (e) {
    return console.error(e.message);
  }
}

export default userSlice.reducer;
