import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import UserYouMightLike from "./userYouMightLike";
import PostSection from "./postSection";
import { getPosts } from "../../toolkit/postSlice";
import { toast } from "react-toastify";

import "./profileContainter.scss";
const ProfileContainter = (props) => {
  const {
    about = "",
    role = [],
    region = "",
    country = "",
    friendsCount = 0,
    subscribeCount = 0,
  } = props.profileData;
  const roleToString = role.join(" | ");

  const [loader, setLoader] = useState(false);
  const [postsData, setPostsData] = useState([]);
  const id = JSON.parse(localStorage.getItem("user")).id;
  const getAllPosts = () => {
    setLoader(true);
    getPosts(id)
      .then((response) => {
        if (response.data) {
          if (response.data.data) {
            setLoader(false);
            let res = [];
            response.data.data.forEach((p) => {
              if (p.notInterested) {
                if (!p.notInterested.includes(id)) res.push(p);
              } else res.push(p);
            });
            let temp = res.sort(
              (p1, p2) => new Date(p2.createdDate) - new Date(p1.createdDate)
            );
            temp = temp.splice(1, 10);
            const renderPostData = temp.map((post) => {
              return <PostSection post={post} />;
            });
            setPostsData(renderPostData);
          }
        }
      })
      .catch((e) => {
        toast.error("Please try again!");
      });
  };
  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <Container fluid>
      <Row>
        {/* First column (About section) */}
        <Col xs={3} className="about-container">
          <h3 className="about-heading">About</h3>
          <p className="about-me">{about}</p>
          <div>
            <p className="about-me">{roleToString}</p>
            <p className="location">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: "10px" }}
                inverse
              />
              {region}, {country}
            </p>
            <p className="about-me">
              <span>{friendsCount}</span> Friends
            </p>
            <p className="about-me">
              <span>{subscribeCount}</span> Subscribers
            </p>
          </div>
        </Col>

        {/* Second column (Main content) */}
        <Col xs={6} style={{ padding: "15px" }}>
          {loader ? (
            <h3>Loading Posts..</h3>
          ) : postsData.length > 0 ? (
            // console.log(postsData, 'postsData')
            postsData
          ) : (
            // postsData.map((post) => {
            //   <PostSection post={post} />;
            // })
            <div>No post found</div>
          )}
        </Col>

        {/* Third column (Might like to follow) */}
        <Col
          xs={3}
          style={{
            backgroundColor: "#121212",
            padding: "15px",
            borderRadius: "1rem",
          }}
        >
          <h3 className="youmightLike">Might Like to Follow</h3>
          <UserYouMightLike />
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileContainter;
