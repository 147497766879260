import React from "react";
import { Image, Container, Navbar, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";

import  "./header.scss";

const Header = () => {
  const history = useNavigate();
  const location = useLocation();

  const id = JSON.parse(localStorage.getItem('user')).id || JSON.parse(localStorage.getItem('user'))._id
  return (
    <>
      <Navbar className="header-bar">
        <div className="w-100 m-0 p-0 d-flex align-item-center">
          <Navbar.Brand href="/" className="text-start px-3 w-50">
          <Image
            src={require("../assets/shockwaveIcon.svg").default}
            className="logo-img"
          />
          </Navbar.Brand>
          <div className="w-50 d-flex white align-items-center justify-content-end">
            <p class="mb-0 me-5">SHOCKWAVE Beta 0.1</p>
            <Dropdown
             align="end"
            >
            <Dropdown.Toggle variant="dark" id="dropdown-basic">
             <i className="fa fa-user"/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item><Link to={'/profile/' + id}>My Profile</Link></Dropdown.Item>
              <Dropdown.Item href="#/action-2">My Entities</Dropdown.Item>
              <Dropdown.Item href="#/action-3">My Projects</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
         </div>
        </div>
      </Navbar>
    </>
  );
};


export default Header;
