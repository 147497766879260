import React from 'react';
import Header from '../header';
import SidebarCol from './sidebar';

const CommonLayout = ({children}) => {

    return <div>
        <div><Header/></div>
        <div className='d-flex layout-page'>
            <div><SidebarCol/></div>
            <div className='child-conatiner'>{children}</div>
        </div>
    </div>

}


export default CommonLayout;