import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { errorMessage, successMessage } from "../../toolkit/toastSlice";
import {
  Image,
  Navbar,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";

import { updateMapUsers } from "../../toolkit/roleSlice";
import { getProfile } from "../../toolkit/usersSlice";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import "./profile.scss";

export const User = () => {
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const profileData = useSelector((state) => state.user.profileData);
  const [iconList, setIconList] = useState([]);
  const [shareDetail, setShareDetails] = useState({});
  useEffect(() => {
    const sharingTitle = `Check out the profile of ${
      JSON.parse(localStorage.getItem("user")).username
    } on SHOCKWAVE, The Entertainment platform.`;
    const sharingUrl = `${window.location.origin}/#/user/${
      JSON.parse(localStorage.getItem("user")).username
    } `;
    setShareDetails({ sharingTitle, sharingUrl });
  }, []);

  useEffect(() => {
    debugger
    dispatch(getProfile(id));
  }, [dispatch, id]);
  useEffect(() => {
    const rolesIcon = [
      { img: "Entertainers/users.svg", name: "Viewer/Listener/User" },
      { img: "Entertainers/Actor.svg", name: "Actor" },
      { img: "Entertainers/Filmmaker.svg", name: "Filmmaker" },
      { img: "Affiliates/Affiliate.svg", name: "Affiliate" },
      { img: "Entertainers/Athlete.svg", name: "Athlete" },
      { img: "Entertainers/Blogger.svg", name: "Blogger" },
      { img: "Entertainers/Vlogger.svg", name: "Vlogger" },
      // { img: "Entertainers/Actor.svg", name: "Comedian" },
      { img: "Entertainers/Dancer.svg", name: "Dancer" },
      { img: "Entertainers/Singer.svg", name: "Emcee" },
      { img: "Entertainers/Gamer.svg", name: "Gamer" },
      { img: "Entertainers/Musician.svg", name: "Musician" },
      { img: "Entertainers/Arts&Crafts.svg", name: "Painter / Crafting" },
      // { img: "Events/Theatre.svg", name: "Theater performer" },
      { img: "Entertainers/Writer.svg", name: "Writer / Poet" },
      { img: "Entertainers/Beatmaker.svg", name: "Beatmaker" },
      { img: "Affiliates/Cameraoperator.svg", name: "Cameraoperator" },
      { img: "Affiliates/Construction.svg", name: "Construction" },
      { img: "Affiliates/Teacher.svg", name: "Teacher" },
      { img: "Affiliates/stage_event.svg", name: "Stage & Event Tech" },
      {
        img: "Affiliates/Logistics_Transport.svg",
        name: "Logistics & Transport",
      },

      {
        img: "Affiliates/Instrumentbuilder.svg",
        name: "Instrumentbuilder",
      },
      { img: "Entertainers/Designer.svg", name: "Designer" },
      { img: "Entertainers/DJ.svg", name: "DJ" },
      { img: "Entertainers/Mixengineer.svg", name: "Mixengineer" },
      { img: "Entertainers/Musiccomposer.svg", name: "Musiccomposer" },
      { img: "Entertainers/Model.svg", name: "Model" },
      { img: "Entertainers/Performanceart.svg", name: "Performanceart" },
      { img: "Entertainers/Petshow.svg", name: "Petshow" },
      { img: "Entertainers/Photographer.svg", name: "Photographer" },
      { img: "Entertainers/Podcaster.svg", name: "Podcaster" },
      { img: "Entertainers/Singer.svg", name: "Singer" },
      { img: "Entertainers/Dance.svg", name: "Dance Event" },
      { img: "Entertainers/DJ.svg", name: "DJ Event" },
      { img: "Entertainers/Game.svg", name: "Game Event" },
      { img: "Entertainers/Music.svg", name: "Music Event" },
      { img: "Entertainers/Petshow.svg", name: "Petshow Event" },
      { img: "Entertainers/Photography.svg", name: "Photography Event" },
      { img: "Entertainers/Sports.svg", name: "Sports Event" },
      { img: "Entertainers/Podcast.svg", name: "Podcast Event" },
      { img: "Entertainers/Theatre.svg", name: "Theatre Event" },
      { img: "Entertainers/Theatre.svg", name: "THouse Location" },
      { img: "Entertainers/Theatre.svg", name: "Theatre Location" },
      { img: "Entertainers/Theatre.svg", name: "Miscellaneous Location" },
      { img: "Entertainers/Theatre.svg", name: "Rehearsal Location" },
      { img: "Entertainers/Theatre.svg", name: "Music Store" },
      { img: "Entertainers/Theatre.svg", name: "Record Store" },
      {
        img: "Entertainers/Theatre.svg",
        name: "Recording Studio Location Event",
      },
    ];
    let roles = [];
    profileData?.role?.map((r) => {
      roles.push(
        rolesIcon.find((i) => {
          if (i.name === r) return i;
        })
      );
    });
    setIconList(roles);
  }, [profileData.role]);

  const showMeOnMap = () => {
    if (profileData.hideOnMap === true) {
     let data = {...profileData, img: ''}
      localStorage.setItem("mapUsers", JSON.stringify([data]));
      dispatch(updateMapUsers(profileData));
      history("/map");
    } else dispatch(errorMessage("You have hided on map !!1"));
  };
  const copyUrl = async () => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/#/user/${JSON.parse(localStorage.getItem("users")).username}`
      );
      var tooltip = document.getElementsByClassName("tooltiptext")[0];
      tooltip.innerHTML = "Copied";
      dispatch(successMessage('Copied'))
    } catch ($e) {
      dispatch(successMessage('Can not Copy'))
    }
  }
  return (
    <div className="profile-page w-100">
      <Row className="">
        <Col md={12} className="banner-header">
          {profileData.img ? (
            <Image src={profileData.img} className="banner-img" />
          ) : (
            <Image
              src={require("../../assets/profile-banner.png")}
              className="banner-img"
            />
          )}
        </Col>
        <Col md={12} className="px-5 pt-5 profile-row">
          {profileData?.profile !== "null" ? (
            <Image src={profileData.profile} className="profile-img" />
          ) : (
            <Image
              src={require("../../assets/users.svg").default}
              className="profile-img"
            />
          )}
        </Col>
      </Row>
      <Row className="w-100 mt-2">
        <Col md={5} className="text-end">
          <Dropdown align="">
            <Dropdown.Toggle variant="dark" id="dropdown-basic">
              <i className="fa fa-share-alt white share-btn" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FacebookShareButton
                  url={shareDetail.sharingUrl}
                  quote={shareDetail.sharingTitle}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <LinkedinShareButton
                  url={shareDetail.sharingUrl}
                  quote={shareDetail.sharingTitle}
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <TwitterShareButton
                  url={shareDetail.sharingUrl}
                  quote={shareDetail.sharingTitle}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <WhatsappShareButton
                  url={shareDetail.sharingUrl}
                  quote={shareDetail.sharingTitle}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
              <button onClick={copyUrl}>Copy</button>
                      <div class="tooltip">
                        <span class="tooltiptext">Copy to clipboard</span>
                      </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col md={2}></Col>
        <Col md={5} className="text-start white username">
          {profileData.username && "@" + profileData.username}
        </Col>
      </Row>
      
      <Row className="">
        <Col md={12} className="white title">
          {profileData.firstname + " " + profileData.lastname}
        </Col>
        <Col md={12} className="text-white">
          {profileData.customRole}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="white">
          {profileData?.role?.map((r, i) => {
            return <span className="role-item">{r}</span>;
          })}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <span className="white text-end">
            <span className="fa fa-compass white compass me-2"></span>
          </span>
          <span className="white me-2">{profileData.country}</span>
          <span className="white text-start me-2">{profileData.region}</span>
          <span>
            {iconList &&
              iconList.length &&
              iconList?.map((icon, i) => {
                return (
                  <Image
                    src={require(`../../assets/${icon.img}`)}
                    className="roleIcons"
                    height={30}
                    width={30}
                    title={icon.name}
                    // src={require("../../assets/Affiliates/Affiliate.svg").default}
                  />
                );
              })}
          </span>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <div class="desc-friends d-flex justify-content-center">
            <div className="me-4">
              <strong>{profileData.friendsCount}</strong> Friends
            </div>
            <div>
              <strong>{profileData.subscribeCount}</strong>
              Subscribers
            </div>
          </div>
        </Col>
      </Row>
      <Container>
        <Row>
          {[1, 2, 3, 4, 5, 6].map(() => (
            <Col className="p-2">
              <Card className="tile"></Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
