import React, { useState, useEffect, useContext  } from "react";
import "./App.scss";
import RouterIndex from "../src/router";
import { Provider } from "react-redux";
import store from "./store";
import { ThemeContext } from "./theme";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import socketIO from 'socket.io-client';

function App() {
  const [isLoading, setLoading] = useState(true);
  const socket = socketIO(process.env.SOCKET_URL, {transports: ['websocket']});
  useEffect(() => {
    // const id = JSON.parse(localStorage.getItem("user"))._id || JSON.parse(localStorage.getItem("user")).id;
  }, []);
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`App ${theme}`}>
      <Provider store={store}>
        {isLoading ? (
          <div>
            <div
              style={{ background: "black", height: "100vh" }}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={require("./assets/introdingetje_van_nieuwe_shit_5_AdobeExpress.gif")}
              />
            </div>
          </div>
        ) : (
          <RouterIndex socket={socket}/>
        )}
        <ToastContainer autoClose={2000} />
      </Provider>
    </div>
  );
}

export default App;
