import React, { useEffect } from "react";
import { Container, Row, Col, Image, Form} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import "./login.scss";
import { getLoginData } from '../../toolkit/usersSlice';
import {warningMessage} from '../../toolkit/toastSlice'

export const Login = () => {
  const isLoading = useSelector((state) => state.user.isLoading)
  const loginUserData = useSelector((state) => state.user.loginUser)
  const userError = useSelector((state) => state.user.error)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const {token} = useParams();
  const { register, handleSubmit, watch, formState: { errors }, reset} = useForm({ defaultValues: { "confirmationCode": token}});
  useEffect(() => {
    if (userError) dispatch(warningMessage(userError));
  }, [userError]);

  useEffect(() => {
    if(Object.keys(loginUserData).length)
      navigate('/services')
  }, [loginUserData])
  const login = (user) => {
    dispatch(getLoginData({...user, "confirmationCode": token}));
  }

  return (
    <div>
      <Container className='login-page'>
        <Row className=''>
            <Col md={12}>
                <Image src={require('../../assets/Logo-new.svg').default} className='topLogo'/>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
            <Form.Control
                type="text"
                placeholder="Email or Username"
                id="email"
                required
                className="box-line"
                {...register("email", { required: true } )}
            />
            {errors.email && <span className="error">This field is required</span>}
            </Col>
        </Row>
        <Row>
            <Col className='my-4' md={12}>
            <Form.Control
                type="password"
                id="password"
                placeholder="Password"
                className="box-line"
                required
                {...register("password", { required: true })}
            />
            {errors.password && <span className="error">This field is required</span>}
            </Col>
            {userError && <span className="error">{userError}</span>}
        </Row>
        <Row>
            <Col className='my-4' md={12}>
              <Link to="/forgot-password">
                      Forgot password?
              </Link>
            </Col>
        </Row>
        <Row>
            <Col className='my-4' md={12}>
            <input
                  type="submit"
                  value="Login"
                  className="signInBtn"
                  disabled={isLoading}
                  onClick={handleSubmit(login)}
                />
            </Col>
        </Row>
    </Container>
      {/* <div className={clsx(classes.mainContent, classes.loginScreen)}>
        <div className={classes.container}>
          <div className={classes.registerForm}>
            <img src={require('../../assets/Logo-new.svg')} className={classes.topLogo} />
            <form>
              <div className={classes.mb3}>
                <input
                  placeholder="Email or Username"
                  v-model="input.email"
                  type="email"
                  className={clsx(classes.formControl, classes.boxLine)}
                  title="Capital letters must be the same on login"
                  required
                />
              </div>
              <div className={classes.mb3}>
                <input
                  placeholder="Password"
                  v-model="input.password"
                  className={clsx(classes.formControl, classes.boxLine)}
                  type="password"
                  required
                />
                <div className={classes.forgotPassword}>
                  <router-link to="/forgotpassword">
                    Forgot password?
                  </router-link>
                </div>
              </div>
              <div className={classes.mb - 3}>
                <input
                  type="submit"
                  value="Login"
                  className={classes.signInBtn}
                  //   disabled={loading}
                  //   onClick={loginUser}
                />
              </div>

              <div className={classes.socialBtn}>
                <a
                  href="#"
                  className={classes.googleSignup}
                  //  onClick={loginWithGoogle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    aria-hidden="true"
                  >
                    <title>Google</title>
                    <g fill="none" fill-rule="evenodd">
                      <path
                        fill="#4285F4"
                        d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
                      ></path>
                      <path
                        fill="#EA4335"
                        d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
                      ></path>
                    </g>
                  </svg>
                  Google
                </a>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
};
