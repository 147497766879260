import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Home } from "./components/Home/home";
import { MapContainer } from "./components/Map/map";

import { Login } from "./components/Login/login";
import { Register } from "./components/Register/register";
import { ForgotPassword } from "./components/ForgotPassword/forgotPassword";
import { ResetPassword } from "./components/ForgotPassword/resetPassword";
import { Terms } from "./components/Terms/terms";
import { Statistics } from "./components/Statistics/statistics";
import { Profile } from "./components/Profile/profile";
import { User } from "./components/Profile/user";

import { Event } from "./components/Event/event";
import { Content } from "./components/Content/content";
import { Market } from "./components/Market/market";
import { Create } from "./components/Create/create";
import { Wallet } from "./components/Wallet/wallet";

import Header from "./components/header";
import CommonLayout from "./components/Layout/commonLayout";
import HomePage from "./components/HomePage/home";
import Services from "./components/Services/services";
import Feed from "./components/Feed/feed";
import { EditProfile } from "./components/EditProfile/editProfile";
import Friends from './components/Friends/Friends';
import BugSystem from './components/BugSystem/bugSystem';

const RouterIndex = (socket) => {
  const RenderComponent = ({ children }) => {
    const navigate = useNavigate();
    useEffect(() => {
      (async () => {
        let session = localStorage.getItem("accessToken");
        if (!session) {
          navigate("/login");
          return null;
        }
      })();
    });
    return (
      <div>
        <Header />
        {children}
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/home"
          element={
            <CommonLayout>
              <HomePage />
            </CommonLayout>
          }
        />
         <Route
          exact
          path="/map"
          element={
            <CommonLayout>
              <MapContainer />
            </CommonLayout>
          }
        />
        <Route
          exact
          path="/feed"
          element={
            <CommonLayout>
              <Feed />
            </CommonLayout>
          }
        />
         <Route exact path="/friends"  element={<CommonLayout><Friends/></CommonLayout>} /> 
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/login/:token" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/resetpassword/:email" element={<ResetPassword />} />

        <Route
          exact
          path="/services"
          element={
            // <CommonLayout>
              <Services socket={socket}/>
            // </CommonLayout>
          }
        />
        <Route
          exact
          path="/profile/:id"
          element={
            <CommonLayout>
              <Profile />
            </CommonLayout>
          }
        />
         <Route
          exact
          path="/user/:id"
          element={
            <CommonLayout>
              <User />
            </CommonLayout>
          }
        />
        <Route
          exact
          path="/editProfile/"
          element={
            <CommonLayout>
              <EditProfile />
            </CommonLayout>
          }
        />

        <Route
          exact
          path="/terms"
          element={
            <CommonLayout>
              <Terms />
            </CommonLayout>
          }
        />
        <Route
          exact
          path="/statistics"
          element={
            <CommonLayout>
              <Statistics />
            </CommonLayout>
          }
        />
        <Route
          exact
          path="/content"
          element={
            <CommonLayout>
              <Content />
            </CommonLayout>
          }
        />
        <Route
          exact
          path="/market"
          element={
            <CommonLayout>
              <Market />
            </CommonLayout>
          }
        />
        <Route
          exact
          path="/event"
          element={
            <CommonLayout>
              <Event />
            </CommonLayout>
          }
        />
        <Route
          exact
          path="/create"
          element={
            <CommonLayout>
              <Create />
            </CommonLayout>
          }
        />
           <Route
          exact
          path="/wallet"
          element={
            <CommonLayout>
              <Wallet />
            </CommonLayout>
          }
        />

        <Route
          exact
          path="/bug-system"
          element={
            <CommonLayout>
              <BugSystem />
            </CommonLayout>
          }
        />

        {/* <Route path="/watch-list"  element={<RenderComponent><List/></RenderComponent>}/> */}
      </Routes>
    </Router>
  );
};

export default RouterIndex;
