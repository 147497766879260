import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';

import {
  onSnapshot,
  getDoc,
  doc,
  setDoc,
  arrayRemove,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../firestore/db";
import {doFileTransfer} from "./fileHandle"
import {isAccepted, isFriendReqSent, isBlocked, sendRequest} from "../../toolkit/friendsSlice";

import "./friends.scss";

const ChatWindow = (props) => {
  const { friend = {} } = props;
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isSelfBlocked, setIsSelfBlocked] = useState(false);
  const [isBlocked_, setIsBlocked] = useState(false);
  const [isAccepted_, setIsAccepted] = useState(false);


  const checkBlocked = () => {
    const selfId = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id
    isBlocked({ id:  friend.friendId, block_id: selfId }).then((response) => {
      setIsBlocked(response.data.blocked)
    });
  }
  const checkSelfBlocked = () => {
    const selfId = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id
    isBlocked({ id: selfId, block_id: friend.friendId }).then((response) => {
      setIsSelfBlocked(response.data.blocked)
    });
  }
  const checkAccepted = () => {
    const selfId = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id
    isAccepted({ id: selfId, user_id: friend.friendId }).then((response) => {
      setIsAccepted(response.data.status)
    });
  }
  const chatRequestSend = (type) => {
    const selfId = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id
    sendRequest({ id: selfId, user_id: friend.friendId }).then((response) => {
      setIsAccepted(response.data.status)
      if (type == "request") {
        // this.showToast({
        //   title: "Succesfully sent chat request!",
        //   type: "success",
        // });
      } else {
        // this.showToast({
        //   title: "Succesfully resent chat request!",
        //   type: "success",
        // });
      }
    });
  }
  const getMessages = async () => {
    checkBlocked();
    checkSelfBlocked();
    checkAccepted();
    let a = isAccepted;
    // Vue.prototype.moment = moment;

    const selfId = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id
    const chatHash = [selfId, friend.friendId].sort().join();
    // userDetail(friend.friendId).then((res) => {
    //   this.partnerData = res.data;
    //   this.firstname = res.data.firstname;
    // });

    const docRef = doc(db, "logs", chatHash);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      let dbData = docSnap.data();
      console.log('data..', dbData.messages)
      setMessages(dbData.messages)
      setTimeout(() => {
        var container = document.querySelector("#chat-log");
        container.scrollTop = container.scrollHeight;
      }, 200);
    } else {
      const newLogRef = doc(db, "logs", chatHash);
      await setDoc(newLogRef, { messages: [] });
      console.log("Creating new log");
    }

    onSnapshot(doc(db, "logs", chatHash), (doc) => {
      let dbData = doc.data();
      if (!dbData?.messages?.length) {
        setMessages([])
      } else {
       setMessages(dbData.messages);
        console.log("message batch: ", messages);
        setTimeout(() => {
          var container = document.querySelector("#chat-log");

          container.scrollTop = container.scrollHeight;
        }, 400);
      }
    });
  }
  const sendMsg = async (e) => {
    const selfId = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id
    const chatHash = [selfId, friend.friendId].sort().join();
    if (userInput.trim().length === 0) {
      return;
    }
    e.preventDefault();
    const msgData = {
      from: selfId,
      to: friend.friendId,
      payload: userInput,
      time: Date.now(),
    };
    const logRef = doc(db, "logs", chatHash);
    let res = await updateDoc(logRef, { messages: arrayUnion(msgData) });
    // this.$socket.emit("incoming_chat", {
    //   to: this.partnerId,
    //   from: this.selfId,
    // });
    // await this.addNotification(this.partnerId);
    setUserInput('')
    
    // console.log('----dfdg', this.onSortList, this.friendsData);
    // $emit('onSortList');

   
  };
  useEffect(() => {
    if(Object.keys(friend).length)
      getMessages();
  }, [friend])

  const [isEmojiPicker, setIsEmojiPicker] = useState(false);
  const handleEmojiClick = (emoji) => {
    setUserInput(userInput + emoji.emoji)
    setIsEmojiPicker(false);
  }
  const selfId = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id

  const sendFile = async (e, file) => {
    const chatHash = [selfId, friend.friendId].sort().join();

    const resUrl = await doFileTransfer(chatHash, file);
    console.log("file stored under url: ", resUrl);
    const msgData = {
      from: selfId,
      to: friend.friendId,
      payload: resUrl,
      time: Date.now(),
      file: true,
    };
    const logRef = doc(db, "logs", chatHash);
    await updateDoc(logRef, { messages: arrayUnion(msgData) });
  }

  return (
    <Container className="chat-window">
      <Row className="chat-box-header">
        <Col className="text-start">
          {friend.profile ? (
            <Image src={friend.profile} className="friend-profile m-2" />
          ) : (
            <Image
              src={require("../../assets/users.svg").default}
              className="friend-profile m-2"
            />
          )}
        </Col>
        <Col className="user-name">
          {friend.firstname + " " + friend.lastname}
        </Col>
        <Col></Col>
      </Row>
      <Row id="chat-log" className="chat-box-body-ful">
        {
          messages.map((msg, i) => {
            return <div
            title={moment(msg.time).fromNow()}
            className={msg.from === selfId && msg.file !== true ? 'chat-msg self': 'chat-msg user'}
            key={i}
          >
            {
              msg.file === true && msg.from !== friend.friendId &&  <div className="chat-msg self">
              <a
                href={msg.payload}
                target="_blank"
                title={moment(msg.time).fromNow()}
                className="file-anchor"
                rel="noreferrer"
              >
                <div className="file-link">
                  <span
                    >📁
                    <p style={{color: '#5a5eb9'}}>YOU SHARED A FILE</p>
                  </span>
                </div>
              </a>
            </div>
            }

             {
              msg.file === true && msg.from === friend.friendId &&  <div className="chat-msg user">
              <a
                href={msg.payload}
                target="_blank"
                title={moment(msg.time).fromNow()}
                className="file-anchor"
                rel="noreferrer"
              >
                <div className="file-link">
                  <span
                    >📁
                    <p style={{color: '#5a5eb9'}}>{ friend.firstname } SHARED A FILE</p>
                  </span>
                </div>
              </a>
            </div>
            }
            {
              msg.file !== true && <div className="cm-msg-text-full">{ msg.payload }</div>
            }
          </div>
          })
        }
      </Row>
      <div className="massage-pannel">
        {
              isBlocked_ ? <div classname="blocked-chat">You are blocked from this User.</div>
            : isSelfBlocked ? <div classname="blocked-chat">You have blocked this User.</div>
            : isAccepted_ == 1 ? <Row>
              <Col className="col-md-2 emoji" >
              {
                isEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} autoFocusSearch={false}/>
              }
              <span><i className="fa fa-smile-o text-white" onClick={() => setIsEmojiPicker(true)}/></span>
              </Col>
              <Col className="col-md-8">
                <div className="input">
                <div className="file-send-btn">
                  <form
                        enctype="multipart/form-data"
                        novalidate
                        classNAme="file-container filesend"
                      >
                        
                        <label
                          for="file-upload"
                          className="custom-file-upload"
                        >
                          <i
                            for="file-upload"
                            className="fa fa-paperclip custom-file-upload icon"
                            aria-hidden="true"
                            onClick={() => {
                              document.getElementById('file-upload').click()
                            }}
                          ></i>
                        </label>

                        <input
                          style={{'display': 'none'}}
                          type="file"
                          id="file-upload"
                          onChange={(event) => sendFile(event.target.name, event.target.files[0])}
                          accept="*"
                          className="input-file"
                        />
                  </form>
                </div>

                  <div>
                  <input
                    type="text"
                    id="chat-input-full"
                    placeholder="Write a Message..."
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    autocomplete="off"
                    className="input-fild"
                  />
                </div>
                </div>
              </Col>
              <Col className="col-md-2 send-btn">
              <button onClick={sendMsg} className="chat-submit send-icon  " id="chat-submit">
                <i classname="fa fa-paper-plane " />
              </button>
              </Col>
              </Row>
            : isAccepted_ == 2 ? <div classname="chat-request-container">Waiting accept request.</div>
            : isAccepted_ == -1 ?   <div classname="chat-request-container">
                <span>Rejected chat request</span>
                <button
                  classname="chat-request-button"
                  onClick={chatRequestSend('rerequest')}
                >
                  Rerequest
                </button>
              </div>
            :  <div classname="chat-request-container">
                <span>Need to request chat</span>
                <button
                  className="chat-request-button"
                  onClick={chatRequestSend('request')}
                >
                  Request
                </button>
              </div>
            
            
            
        }
     
      </div>
    </Container>
  );
};

export default ChatWindow;
