import React from "react";
import "./home.scss";

const HomePage = () => {
  return (
    <div className="homepage-container">
      <p>
        Browse with{" "}
        <a href="https://shockwave.lol" target="_blank" rel="noreferrer">
          HTTPS://shockwave.lol/
        </a>
      </p>
    </div>
  );
};

export default HomePage;
