import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./toolkit/usersSlice";
import rolesReducer from "./toolkit/roleSlice";
import toastSlice from "./toolkit/toastSlice";
import statisticsSlice from "./toolkit/statisticsSlice";
import friendsSlice from "./toolkit/friendsSlice";
import NFTSlice from "./toolkit/NFTSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    roles: rolesReducer,
    toast: toastSlice,
    statistics: statisticsSlice,
    friends: friendsSlice,
    NFT: NFTSlice,
  },
});

export default store;