import { createSlice, current } from "@reduxjs/toolkit";
import { axiosInstance } from "../services";

export const rolesSlice = createSlice({
  name: "role",
  initialState: {
    roles: [
      { name: "Viewer/Listener/User", code: "1" },
      { name: "Actor", code: "3" },
      { name: "Affiliate", code: "4" },
      { name: "Athlete", code: "5" },
      { name: "Blogger", code: "6" },
      // { name: "Comedian", code: "7" },
      { name: "Dancer", code: "8" },
      { name: "Emcee", code: "9" },
      { name: "Gamer", code: "10" },
      { name: "Musician", code: "11" },
      { name: "Painter / Crafting", code: "12" },
      // { name: "Theater performer", code: "13" },
      { name: "Writer / Poet", code: "14" },
      { name: "Beatmaker", code: "15" },
      { name: "Cameraoperator", code: "16" },
      { name: "Designer", code: "17" },
      { name: "DJ", code: "18" },
      { name: "Mixengineer", code: "19" },
      { name: "Musiccomposer", code: "20" },
      { name: "Musicproducer", code: "21" },
      { name: "Performanceart", code: "22" },
      { name: "Petshow", code: "23" },
      { name: "Photographer", code: "24" },
      { name: "Podcaster", code: "25" },
      { name: "Singer", code: "26" },
      { name: "Instrumentbuilder", code: "27" },
      { name: "Model", code: "28" },
      { name: "Construction", code: "29" },
      { name: "Teacher", code: "30" },
      { name: "Stage & Event Tech", code: "31" },
      { name: "Logistics & Transport", code: "32" },
      { name: "Vlogger", code: "33" },
      { name: "Flimmaker", code: "34" },
      { name: "Luthier store", code: "35" },
    ],
    rolesIcon: [
      { img: "Entertainers/users.svg", name: "Viewer/Listener/User" },
      { img: "Entertainers/Actor.svg", name: "Actor" },
      { img: "Entertainers/Filmmaker.svg", name: "Filmmaker" },
      { img: "Affiliates/Affiliate.svg", name: "Affiliate" },
      { img: "Entertainers/Athlete.svg", name: "Athlete" },
      { img: "Entertainers/Blogger.svg", name: "Blogger" },
      { img: "Entertainers/Vlogger.svg", name: "Vlogger" },
      // { img: "Entertainers/Actor.svg", name: "Comedian" },
      { img: "Entertainers/Dancer.svg", name: "Dancer" },
      { img: "Entertainers/Singer.svg", name: "Emcee" },
      { img: "Entertainers/Gamer.svg", name: "Gamer" },
      { img: "Entertainers/Musician.svg", name: "Musician" },
      { img: "Entertainers/Musicproducer.svg", name: "Musicproducer" },
      { img: "Entertainers/Arts&Crafts.svg", name: "Painter / Crafting" },
      // { img: "Events/Theatre.svg", name: "Theater performer" },
      { img: "Entertainers/Writer.svg", name: "Writer / Poet" },
      { img: "Entertainers/Beatmaker.svg", name: "Beatmaker" },
      { img: "Affiliates/Cameraoperator.svg", name: "Cameraoperator" },
      { img: "Affiliates/Construction.svg", name: "Construction" },
      { img: "Affiliates/Teacher.svg", name: "Teacher" },
      { img: "Affiliates/stage_event.svg", name: "Stage & Event Tech" },
      {
        img: "Affiliates/Logistics_Transport.svg",
        name: "Logistics & Transport",
      },

      {
        img: "Affiliates/Instrumentbuilder.svg",
        name: "Instrumentbuilder",
      },
      { img: "Entertainers/Designer.svg", name: "Designer" },
      { img: "Entertainers/DJ.svg", name: "DJ" },
      { img: "Entertainers/Mixengineer.svg", name: "Mixengineer" },
      { img: "Entertainers/Musiccomposer.svg", name: "Musiccomposer" },
      { img: "Entertainers/Model.svg", name: "Model" },
      { img: "Entertainers/Performanceart.svg", name: "Performanceart" },
      { img: "Entertainers/Petshow.svg", name: "Petshow" },
      { img: "Entertainers/Photographer.svg", name: "Photographer" },
      { img: "Entertainers/Podcaster.svg", name: "Podcaster" },
      { img: "Entertainers/Singer.svg", name: "Singer" },
      { img: "Entertainers/Dance.svg", name: "Dance Event" },
      { img: "Entertainers/DJ.svg", name: "DJ Event" },
      { img: "Entertainers/Game.svg", name: "Game Event" },
      { img: "Entertainers/Music.svg", name: "Music Event" },
      { img: "Entertainers/Petshow.svg", name: "Petshow Event" },
      { img: "Entertainers/Photography.svg", name: "Photography Event" },
      { img: "Entertainers/Sports.svg", name: "Sports Event" },
      { img: "Entertainers/Podcast.svg", name: "Podcast Event" },
      { img: "Entertainers/Theatre.svg", name: "Theatre Event" },
      { img: "Entertainers/Theatre.svg", name: "THouse Location" },
      { img: "Entertainers/Theatre.svg", name: "Theatre Location" },
      { img: "Entertainers/Theatre.svg", name: "Miscellaneous Location" },
      { img: "Entertainers/Theatre.svg", name: "Rehearsal Location" },
      { img: "Entertainers/Theatre.svg", name: "Music Store" },
      { img: "Entertainers/Theatre.svg", name: "Record Store" },
      {
        img: "Entertainers/Theatre.svg",
        name: "Recording Studio Location Event",
      },
    ],
    categoryWiseRole: [
      {
        name: "Users",
        css_class: "user_input",
        data: [{ name: "Viewer/Listener/User", css_class: "viewer_input" }],
      },
      {
        name: "Entertainers",
        css_class: "entertainers_input",
        data: [
          { name: "Actor", css_class: "action_input" },
          { name: "Filmmaker", css_class: "action_input" },
          { name: "Athlete", css_class: "athlete_input" },
          { name: "Blogger", css_class: "blogger_input" },
          { name: "Vlogger", css_class: "blogger_input" },
          // { name: "Comedian", css_class: "comedian_input" },
          { name: "Dancer", css_class: "dancer_input" },
          { name: "Emcee", css_class: "emcee_input" },
          { name: "Gamer", css_class: "gamer_input" },
          { name: "Musician", css_class: "musician_input" },
          { name: "Painter / Crafting", css_class: "painter_input" },
          // { name: "Theater performer", css_class: "theater_input" },
          { name: "Writer / Poet", css_class: "writer_input" },
          { name: "Beatmaker", css_class: "writer_input" },
          { name: "Designer", css_class: "writer_input" },
          { name: "DJ", css_class: "writer_input" },
          { name: "Mix engineer", css_class: "writer_input" },
          { name: "Music composer", css_class: "writer_input" },
          { name: "Music producer", css_class: "writer_input" },
          { name: "Model", css_class: "model_input" },
          { name: "Performance art", css_class: "writer_input" },
          { name: "Petshow", css_class: "writer_input" },
          { name: "Photographer", css_class: "writer_input" },
          { name: "Podcaster", css_class: "writer_input" },
          { name: "Singer", css_class: "writer_input" },
        ],
      },
      {
        name: "Affiliates",
        css_class: "user_input",
        data: [
          { name: "Affiliate", css_class: "affiliate_input" },
          { name: "Camera Operator", css_class: "writer_input" },
          { name: "Instrument Builder", css_class: "writer_input" },
          { name: "Construction", css_class: "construction_input" },
          { name: "Teacher", css_class: "teacher_input" },
          { name: "Stage & Event Tech", css_class: "stage_event_input"},
          { name: "Logistics & Transport", css_class: "affiliate_input" },
          

        ],
      },
      {
        name: "Events",
        css_class: "events_input",
        data: [
          { name: "Dance Event", css_class: "writer_input" },
          { name: "DJ Event", css_class: "writer_input" },
          { name: "Game Event", css_class: "writer_input" },
          { name: "Music Event", css_class: "writer_input" },
          { name: "Petshow Event", css_class: "writer_input" },
          { name: "Photography Event", css_class: "writer_input" },
          { name: "Podcast Event", css_class: "writer_input" },
          { name: "Sports Event", css_class: "writer_input" },
          { name: "Theatre Event", css_class: "writer_input" },
        ],
      },

      {
        name: "Locations",
        css_class: "location_input",
        data: [
          { name: "Music Venue Location", css_class: "writer_input" },
          { name: "Recording Studio Location", css_class: "writer_input" },
          { name: "House Location", css_class: "writer_input" },
          { name: "Theatre Location", css_class: "writer_input" },
          { name: "Rehearsal Location", css_class: "writer_input" },
          { name: "Miscellaneous Location", css_class: "writer_input" },
        ],
      },

      {
        name: "Stores",
        css_class: "stores_input",
        data: [
          { name: "Music Store", css_class: "writer_input" },
          { name: "Record Store", css_class: "writer_input" },
          { name: "Luthier store", css_class: "writer_input"}
        ],
      },
    ],
    isLoading: false,
    error: "",
    roleWiseUsers: [],
  },
  reducers: {
    setLoading: (state, action) => {
      console.log(current(state));
      state.isLoading = action.payload;
      state.roles = [];
      state.error = "";
    },
    getRoles: (state, action) => {
      state.roles = action.payload;
      state.error = "";
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.roles = [];
    },
    getRoleWiseUsers: (state, action) => {
      localStorage.setItem('mapUsers', JSON.stringify(action.payload))
      state.roleWiseUsers = action.payload
      // state.error = action.payload;
      state.isLoading = false;
    },
    updateRoleWiseUsers: (state, action) => {
      state.roleWiseUsers = state.roleWiseUsers.filter(x => !x.role.includes(action.payload))
      localStorage.setItem('mapUsers', JSON.stringify(state.roleWiseUsers.filter(x => !x.role.includes(action.payload))))
      // state.error = action.payload;
      state.isLoading = false;
    },
    updateUsers: (state, action) => {
      state.roleWiseUsers = [...state.roleWiseUsers, action.payload]
      state.isLoading = false;
    },
  },
});

export const { getRoles, setLoading, hasError, getRoleWiseUsers, updateRoleWiseUsers, updateUsers } = rolesSlice.actions;

export const getRolesData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .post("/users/authenticate", { payload })
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "401")
          return dispatch(hasError(response.data.msg));

        return dispatch(getRoles(response.data));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getRoleWiseUsersData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance
      .post("/users/role", { ...payload })
      .then((response) => {
        dispatch(setLoading(false));
        if (response.data.code === "401")
          return dispatch(hasError(response.data.msg));

        return dispatch(getRoleWiseUsers(response.data));
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateRoleWiseUsersData = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    return dispatch(updateRoleWiseUsers(payload));
   
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateMapUsers = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    return dispatch(updateUsers(payload));
   
  } catch (e) {
    return console.error(e.message);
  }
};

export default rolesSlice.reducer;
