import React, { useEffect } from "react";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import "./forgotPassword.scss";
import { resetPasswordData } from "../../toolkit/usersSlice";
import { successMessage } from "../../toolkit/toastSlice";

export const ResetPassword = () => {
  const isLoading = useSelector((state) => state.user.isLoading);
  const resetPasswordSuccessMessage = useSelector((state) => state.user.resetPasswordSuccessMessage);
  const resetPasswordSuccessErr = useSelector((state) => state.user.error);
  const dispatch = useDispatch();
  const history = useNavigate();

  const {email} = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {email: email  } });

  useEffect(() => {
    if(resetPasswordSuccessMessage.length) {
        dispatch(successMessage(resetPasswordSuccessMessage))
        reset();
      history(`/login`)
    }
  }, [resetPasswordSuccessMessage])

  useEffect(() => {
    if(resetPasswordSuccessErr.length)
        alert(resetPasswordSuccessErr)
  }, [resetPasswordSuccessErr])

  const resetPassword = (user) => {
    dispatch(resetPasswordData(user));
    if (!resetPasswordSuccessErr) {
      reset();
    }
  };
  return (
    <div>
      <Container className="forgot-page">
       
        <Row>
        <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="password"
                placeholder="Password"
                id="password"
                required
                className="box-line"
                {...register("password", {
                  required: "password is required",
                  validate: {
                    matchPattern: (v) =>
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) ||
                      "Match the passwrod pattern",
                  },
                })}
              />
              {errors.password && (
                <span className="error">{errors.password.message}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                id="confirmpassword"
                required
                className="box-line"
                {...register("passwordConf", {
                  required: true,
                  validate: (val) => {
                    if (watch("password") !== val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
              />
              {errors.passwordConf && (
                <span className="error">{errors.passwordConf.message}</span>
              )}
              <span>
                Please use One capital letter and one special character in your
                password
              </span>
            </Col>
        </Row>

        <Row>
          <Col className="my-4" md={12}>
            <input
              type="submit"
              value="Submit"
              className="signInBtn"
              disabled={isLoading}
              onClick={handleSubmit(resetPassword)}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
