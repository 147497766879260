import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ThemeContext } from "../../theme"

import "./create.css";

export const Create = () => {
  const [selectedButton, setSelectedButton] = React.useState("");
  return (
    <Container className="createContainer">
      {!selectedButton && (
      <>
        <div class="text">
          <h3>I would like to...</h3>
          </div>
          {/* <Row> */}
            {/* <Col> */}
              <Button onClick={() => setSelectedButton("create")}  className="create-btn"> 
                Create
              </Button>
            {/* </Col> */}
            {/* <Col> */}
              <Button onClick={() => setSelectedButton("edit")}  className="edit-btn">
                Edit
                </Button>
            {/* </Col> */}
          {/* </Row> */}
          </>
      )}
      {selectedButton === 'create' && (
        <div className="create-section">
          {/* <Row> */}
            {/* <Col> */}
              <Button className="create-entity">
                Create Entity
              </Button>
              <Button className="create-project">
                Create Project
              </Button>
              <Button className="create-content">
                Create Content
              </Button>
              <Button className="create-event">
                Create Event
              </Button>
            {/* </Col> */}
           
          {/* </Row> */}
        </div>
      )}
      {selectedButton === 'edit' && (
        <div class="edit-section">
          {/* <Row> */}
            {/* <Col> */}
              <Button className="edit-entity">
                Edit Entity
              </Button>
              <Button className="edit-project">
                Edit Project
              </Button>
              <Button className="edit-content">
                Edit Content
              </Button>
              <Button className="edit-event">
                Edit Event
              </Button>
            {/* </Col> */}
           
          {/* </Row> */}
        </div>
      )}
    </Container>
  );
};
