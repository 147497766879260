import React, { useState, useCallback, useEffect } from "react";
import { Button, Image, Row, Col, Container } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoBox,
  InfoWindow,
} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import Drawer from "react-modern-drawer";
import RangeSlider from "react-bootstrap-range-slider";
import Switch from "react-switch";
import "react-modern-drawer/dist/index.css";
import "./map.css";
import {
  getRoleWiseUsersData,
  updateRoleWiseUsersData,
} from "../../toolkit/roleSlice";
import {friends, isFriendReqSent, friendrequest, updateAddFriend} from "../../toolkit/friendsSlice";
import { hideOnMapData, getMiniProfileData } from "../../toolkit/usersSlice";
import ChatPopuup from '../Chat/chatPopup';
export const MapContainer = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const loginUser = useSelector((state) => state.user.loginUser);
  const miniProfile = useSelector((state) => state.user.miniProfile);
  const isLoading = useSelector((state) => state.user.isLoading);

  const categoryWiseRole = useSelector((state) => state.roles.categoryWiseRole);
  const roleWiseUsers_ = useSelector((state) => state.roles.roleWiseUsers);
  const [roleWiseUsers, setRoleWiseUsers] = React.useState(
    JSON.parse(localStorage.getItem("mapUsers")) || []
  );
  const [miniProfileData, setMiniProfileData] = useState();
  const [map, setMap] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [rangeValue, setRangeValue] = React.useState(100);

  const [searchValue, setSearchValue] = React.useState();

  const [selectedRoles, setSelectedRoles] = React.useState(
    JSON.parse(localStorage.getItem("selectedRoles")) || []
  );

  const [activeMarker, setActiveMarker] = useState(-1);
  const [infoDomReady, setInfoDomReady] = useState(false);

  useEffect(() => {
    (async () => {
      if(miniProfile) {
        let boolVar = await arefriends(miniProfile._id);
        let isFrndReq = await isFriendReqSend(miniProfile._id);
        const user = {...miniProfile, isFriend: boolVar, isFriendReqSent: isFrndReq };
      setMiniProfileData(user)
      }
    })();
  
  }, [miniProfile])
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleInfoCloseClick = () => {
    setActiveMarker(-1);
    setInfoDomReady(false);
  };

  useEffect(() => {
    localStorage.setItem("range", rangeValue);
  }, [rangeValue]);

  useEffect(() => {
    setRoleWiseUsers(
      localStorage.getItem("mapUsers")
        ? JSON.parse(localStorage.getItem("mapUsers"))
        : []
    );
  }, [roleWiseUsers_]);

  const assignRoleImages = () => {
    selectedRoles?.forEach((y) => {
      if (y == "Viewer/Listener/User") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/users.svg";
          x.name = "Viewer/Listener/User";
        });
      }
      if (y == "Actor") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Actor.svg";
          x.name = "Actor";
        });
      }
      if (y == "Filmmaker") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Filmmaker.svg";
          x.name = "Filmmaker";
        });
      }
      if (y == "Affiliate") {
        roleWiseUsers.forEach((x) => {
          x.img = "Affiliates/Affiliate.svg";
          x.name = "Affiliate";
        });
      }
      if (y == "Instrumentbuilder") {
        roleWiseUsers.forEach((x) => {
          x.img = "Affiliates/Instrumentbuilder.svg";
          x.name = "Affiliate";
        });
      }
      if (y == "Athlete") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Athlete.svg";
          x.name = "Athlete";
        });
      }
      if (y == "Blogger") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Blogger.svg";
          x.name = "Blogger";
        });
      }
      if (y == "Vlogger") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Vlogger.svg";
          x.name = "Vlogger";
        });
      }
      // if (y == "Comedian") {
      //   roleWiseUsers.forEach((x) => {
      //     x.img = "Entertainers/Actor.svg";
      //     x.name = "Comedian";
      //   });
      // }
      if (y == "Dancer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Dancer.svg";
          x.name = "Dancer";
        });
      }
      if (y == "Emcee") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Emcee.svg";
          x.name = "Emcee";
        });
      }
      if (y == "Gamer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Gamer.svg";
          x.name = "Gamer";
        });
      }
      if (y == "Musician") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Musician.svg";
          x.name = "Musician";
        });
      }
      if (y == "Painter / Crafting") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Arts&Crafts.svg";
          x.name = "Painter / Crafting";
        });
      }
      // if (y == "Theater performer") {
      //   roleWiseUsers.forEach((x) => {
      //     x.img = "Events/Events/Theatre.svg";
      //     x.name = "Theater performer";
      //   });
      // }
      if (y == "Writer / Poet") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Writer.svg";
          x.name = "Writer / Poet";
        });
      }
      if (y == "Beatmaker") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Beatmaker.svg";
          x.name = "Beatmaker";
        });
      }
      if (y == "Cameraoperator") {
        roleWiseUsers.forEach((x) => {
          x.img = "Affiliates/Cameraoperator.svg";
          x.name = "Cameraoperator";
        });
      }
      if (y == "Construction") {
        roleWiseUsers.forEach((x) => {
          x.img = "Affiliates/Construction.svg";
          x.name = "Construction";
        });
      }
      if (y == "Teacher") {
        roleWiseUsers.forEach((x) => {
          x.img = "Affiliates/Teacher.svg";
          x.name = "Teacher";
        });
      }
      if (y == "Stage & Event Tech") {
        roleWiseUsers.forEach((x) => {
          x.img = "Affiliates/stage_event.svg";
          x.name = "Stage & Event Tech";
        });
      }
      if (y == "Logistics & Transport") {
        roleWiseUsers.forEach((x) => {
          x.img = "Affiliates/Logistics_Transport.svg";
          x.name = "Logistics & Transport";
        });
      }
      if (y == "Designer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Designer.svg";
          x.name = "Designer";
        });
      }
      if (y == "DJ") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/DJ.svg";
          x.name = "DJ";
        });
      }
      if (y == "Mixengineer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Mixengineer.svg";
          x.name = "Mixengineer";
        });
      }
      if (y == "Musiccomposer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Musiccomposer.svg";
          x.name = "Musiccomposer";
        });
      }
      if (y == "Musicproducer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Musicproducer.svg";
          x.name = "Musicproducer";
        });
      }
      if (y == "Model") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Model.svg";
          x.name = "Model";
        });
      }
      if (y == "Performanceart") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Performanceart.svg";
          x.name = "Performanceart";
        });
      }
      if (y == "Petshow") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Petshow.svg";
          x.name = "Petshow";
        });
      }
      if (y == "Photographer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Photographer.svg";
          x.name = "Photographer";
        });
      }
      if (y == "Podcaster") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Podcaster.svg";
          x.name = "Podcaster";
        });
      }
      if (y == "Singer") {
        roleWiseUsers.forEach((x) => {
          x.img = "Entertainers/Singer.svg";
          x.name = "Singer";
        });
      }
      if (y == "Dance Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Dance.svg";
          x.name = "Dance Event";
        });
      }
      if (y == "DJ Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/DJ.svg";
          x.name = "Dj Event";
        });
      }
      if (y == "Game Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Game.svg";
          x.name = "Game Event";
        });
      }
      if (y == "Music Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Music.svg";
          x.name = "Music Event";
        });
      }
      if (y == "Petshow Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Petshow.svg";
          x.name = "Petshow Event";
        });
      }
      if (y == "Photography Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Photography.svg";
          x.name = "Photography Event";
        });
      }
      if (y == "Podcast Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Podcast.svg";
          x.name = "Podcast Event";
        });
      }
      if (y == "Sports Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Sports.svg";
          x.name = "Sports Event";
        });
      }
      if (y == "Theatre Event") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Theatre Event";
        });
      }
      if (y == "Music Venue Location") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Music Venue Location";
        });
      }
      if (y == "Recording Studio Location") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Recording Studio Location";
        });
      }
      if (y == "House Location") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "House Location";
        });
      }
      if (y == "Theatre Location") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Theatre Location";
        });
      }
      if (y == "Rehearsal Location") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Rehearsal Location";
        });
      }
      if (y == "Miscellaneous Location") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Miscellaneous Location";
        });
      }

      if (y == "Music Store") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Music Store";
        });
      }
      if (y == "Record Store") {
        roleWiseUsers.forEach((x) => {
          x.img = "Events/Theatre.svg";
          x.name = "Record Store";
        });
      }
      if (y == "Luthier store") {
        roleWiseUsers.forEach((x) => {
          x.img = "luthierstore.svg";
          x.name = "Luthier store";
        });
      }
    });
  };

  useEffect(() => {
    assignRoleImages();
  }, [selectedRoles]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const submitSearch = () => {
    const payload = {
      lat: JSON.parse(localStorage.getItem("user")).lat,
      lng: JSON.parse(localStorage.getItem("user")).lng,
      hobbies: [searchValue],
      distance: 6000,
      isPremium: true,
      range: localStorage.getItem("range")
        ? localStorage.getItem("range")
        : 100,
    };
    getSelectedRolesData(payload);
  };

  const getSelectedRolesData = (payload) => {
    dispatch(getRoleWiseUsersData(payload));
  };

  const handleRoleChange = (roleName, checked) => {
    let roles = selectedRoles;
    if (checked) {
      roles.push(roleName);
      const payload = {
        lat: JSON.parse(localStorage.getItem("user")).lat,
        lng: JSON.parse(localStorage.getItem("user")).lng,
        hobbies: roles,
        distance: 6000,
        isPremium: true,
        range: localStorage.getItem("range")
          ? localStorage.getItem("range")
          : 100,
      };
      let arr = selectedRoles;
      arr.push(roleName);
      localStorage.setItem("selectedRoles", JSON.stringify(arr));
      getSelectedRolesData(payload);
      setSelectedRoles(arr);

    } else {
      roles.splice(
        roles.findIndex((x) => x == roleName),
        1
      );
      let arr = selectedRoles.filter((x) => x !== roleName);
      localStorage.setItem("selectedRoles", JSON.stringify(arr));
      dispatch(updateRoleWiseUsersData(roleName));
      setSelectedRoles(arr);

    }
  };

  const hideOnMap = (value) => {
    dispatch(hideOnMapData(JSON.parse(localStorage.getItem("user")).id));
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA1ycCaZ9Nv0Rf9mqH22zRw72RihY2moDg",
  });

  const getLatLong = (user) => {
    var location = { lat: parseFloat(user.lat), lng: parseFloat(user.lng) };
    return location;
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
  };
  // const center = {
  //   lat: 19.076,
  //   lng: 72.8777,
  // };
  const [center, setCenter] = React.useState({
    lat: 45.508,
    lng: -73.587,
  });
  const [useCurrentLocation, setUseCUrrentLocation] = React.useState(false);

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  let iconMarker =
    roleWiseUsers.length && roleWiseUsers[0]?.img
      ? new window.google.maps.MarkerImage(
          require(`../../assets/${roleWiseUsers[0].img}`).default,
          null /* size is determined at runtime */,
          null /* origin is 0,0 */,
          null /* anchor is bottom center of the scaled image */,
          new window.google.maps.Size(32, 32)
        )
      : new window.google.maps.MarkerImage(
          require(`../../assets/users.svg`).default,
          null /* size is determined at runtime */,
          null /* origin is 0,0 */,
          null /* anchor is bottom center of the scaled image */,
          new window.google.maps.Size(32, 32)
        );
  const defaultLocation = () => {
    setCenter({
      lat: parseFloat(JSON.parse(localStorage.getItem("user")).lat),
      lng: parseFloat(JSON.parse(localStorage.getItem("user")).lng),
    });
  };
  const switchLocation = (boolVar) => {
    setUseCUrrentLocation(boolVar);
    if (boolVar) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      // defaultLocation();
      setCenter({
        lat: parseFloat(JSON.parse(localStorage.getItem("user")).lat),
        lng: parseFloat(JSON.parse(localStorage.getItem("user")).lng),
      });
    }
  };
  const [selectedMarkerUser, setSelectedMarkerUser] = React.useState({});
  React.useEffect(() => {
    // Get whole detail by ID
  }, [selectedMarkerUser]);
  const handleMarkerClick = (user, i) => {
    setSelectedMarkerUser(user);
    // Get user's mini profile
    dispatch(getMiniProfileData(user._id));
    handleActiveMarker(i);
  };
  const [iconList, setIconList] = useState([]);
  const rolesIcon = [
    { img: "Entertainers/users.svg", name: "Viewer/Listener/User" },
    { img: "Entertainers/Actor.svg", name: "Actor" },
    { img: "Entertainers/Filmmaker.svg", name: "Filmmaker" },
    { img: "Affiliates/Affiliate.svg", name: "Affiliate" },
    { img: "Entertainers/Athlete.svg", name: "Athlete" },
    { img: "Entertainers/Blogger.svg", name: "Blogger" },
    { img: "Entertainers/Vlogger.svg", name: "Vlogger" },
    // { img: "Entertainers/Actor.svg", name: "Comedian" },
    { img: "Entertainers/Dancer.svg", name: "Dancer" },
    { img: "Entertainers/Emcee.svg", name: "Emcee" },
    { img: "Entertainers/Gamer.svg", name: "Gamer" },
    { img: "Entertainers/Musician.svg", name: "Musician" },
    { img: "Entertainers/Arts&Crafts.svg", name: "Painter / Crafting" },
    // { img: "Events/Events/Theatre.svg", name: "Theater performer" },
    { img: "Entertainers/Writer.svg", name: "Writer / Poet" },
    { img: "Entertainers/Beatmaker.svg", name: "Beatmaker" },
    { img: "Affiliates/Cameraoperator.svg", name: "Cameraoperator" },
    { img: "Affiliates/Construction.svg", name: "Construction" },
    { img: "Affiliates/Teacher.svg", name: "Teacher" },
    { img: "Affiliates/stage_event.svg", name: "Stage & Event Tech" },
    {
      img: "Affiliates/Logistics_Transport.svg",
      name: "Logistics & Transport",
    },

    {
      img: "Affiliates/Instrumentbuilder.svg",
      name: "Instrumentbuilder",
    },
    { img: "Entertainers/Designer.svg", name: "Designer" },
    { img: "Entertainers/DJ.svg", name: "DJ" },
    { img: "Entertainers/Mixengineer.svg", name: "Mixengineer" },
    { img: "Entertainers/Musiccomposer.svg", name: "Musiccomposer" },
    { img: "Entertainers/Model.svg", name: "Model" },
    { img: "Entertainers/Performanceart.svg", name: "Performanceart" },
    { img: "Entertainers/Petshow.svg", name: "Petshow" },
    { img: "Entertainers/Photographer.svg", name: "Photographer" },
    { img: "Entertainers/Podcaster.svg", name: "Podcaster" },
    { img: "Entertainers/Singer.svg", name: "Singer" },
    { img: "Entertainers/Dance.svg", name: "Dance Event" },
    { img: "Events/DJ.svg", name: "DJ Event" },
    { img: "Entertainers/Game.svg", name: "Game Event" },
    { img: "Entertainers/Music.svg", name: "Music Event" },
    { img: "Entertainers/Petshow.svg", name: "Petshow Event" },
    { img: "Entertainers/Photography.svg", name: "Photography Event" },
    { img: "Entertainers/Sports.svg", name: "Sports Event" },
    { img: "Entertainers/Podcast.svg", name: "Podcast Event" },
    { img: "Events/Theatre.svg", name: "Theatre Event" },
    { img: "Events/Theatre.svg", name: "THouse Location" },
    { img: "Events/Theatre.svg", name: "Theatre Location" },
    { img: "Events/Theatre.svg", name: "Miscellaneous Location" },
    { img: "Events/Theatre.svg", name: "Rehearsal Location" },
    { img: "Events/Theatre.svg", name: "Music Store" },
    { img: "Events/Theatre.svg", name: "Record Store" },
    {
      img: "Events/Theatre.svg",
      name: "Recording Studio Location Event",
    },
  ];
  useEffect(() => {
    
    let roles = [];
    miniProfile?.role?.map((r) => {
      roles.push(
        rolesIcon.find((i) => {
          if (i.name === r) return i;
        })
      );
    });
    setIconList(roles);
  }, [miniProfile?.role]);

  const infoBoxOpts = {
    closeBoxURL: "",
    infoBoxClearance: new window.google.maps.Size(24, 24),
    pixelOffset: new window.google.maps.Size(-150, -60),
    alignBottom: true,
  };

  const arefriends = async (friend_id) => {
    const id = JSON.parse(localStorage.getItem("user")).id
    const data = {
      user_id: id,
      friendrequest_id: friend_id,
    };
    let output = false;
    let response = await friends(data);
    if (Object.keys(response.data).length) {
      output = true;
    }
    return output;
  }

  const isFriendReqSend = async(friend_id) => {
    const id = JSON.parse(localStorage.getItem("user")).id
    const data = {
      user_id: id,
      friendrequest_id: friend_id,
    };
    let output = false;
    let response = await isFriendReqSent(data);
    if (Object.keys(response.data).length) {
      output = true;
    }
    return output;
  }

  const [isFriends, setIsFriends] = useState(false);
  const addFriend = ( request_id,
    request_firstname,
    request_lastname,
    request_email,
    friend_value) => {
     
      const user_id = JSON.parse(localStorage.getItem("user"))._id || JSON.parse(localStorage.getItem("user")).id;
      const user_email = JSON.parse(localStorage.getItem("user")).email
      const user_name = JSON.parse(localStorage.getItem("user")).username
      const req_firstname =
        request_firstname.charAt(0).toUpperCase() + request_firstname.slice(1);
      const req_lastname =
        request_lastname.charAt(0).toUpperCase() + request_lastname.slice(1);

      const data = {
        user_id: user_id,
        friendrequest_id: request_id,
        friendrequest_name: req_firstname + " " + req_lastname,
        friendrequest_status: "Not Selected",
        user_name: user_name,
        user_email: user_email,
        req_email: request_email,
        friends: friend_value,
      };
      console.log("req_email", data.req_email);
      friendrequest(data)
        .then(async (response) => {
          setIsFriends(response.data.user.friends)
          if (isFriends == true) {
            console.log("Friend_True", isFriends);
            setMiniProfileData({...miniProfileData, isFriendReqSent: true, isFriend: false})
            // this.showToast({
            //   title: "Friend request sent successfull!",
            //   type: "success",
            // });
          } else {
            // this.showToast({
            //   title: "Unfriend successfully!",
            //   type: "success",
            // });
          }
          
        })
        .catch((err) => {
          // this.showToast({
          //   title: err.response.data.message,
          //   type: "error",
          // });
        });
  }
  const friendStatusUpdate = (id, friends) => {
    let data = {
      id: id,
      friends: friends,
    };
    updateAddFriend(data)
      .then((response) => {
        console.log("friendStatusUpdate", response.data);
        // getAllUsers();
      })
      .catch((err) => {
        // this.showToast({
        //   title: err.response.data.message,
        //   type: "error",
        // });
      });
  }
  const [isChatOpen, setIsChatOpen] = useState(false);
  const openChat = () => {
    setIsChatOpen(!isChatOpen)
  }
  return (
    <div id="map" className="mapContainer">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={7}
          // minZoom={2}
          // maxZoom={14}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {roleWiseUsers?.length &&
            roleWiseUsers?.map((user, i) => {
              const a = rolesIcon.find(x => {
                // console.log(x)

                // console.log(x.img.split('/')[1].split('.')[0])
               if( user?.role?.includes(x.img.split('/')[1].split('.')[0])) {
                return x.img
               }
              });
              if(!a?.img){
                return null
              }
             
              return (
                <MarkerF
                  position={getLatLong(user)}
                  // position={{
                  //   lat: 21.1702,
                  //   lng: 72.8311,
                  // }}
                  icon={{
                    url:require(`../../assets/${a?.img}`),
                    scaledSize: new window.google.maps.Size(30, 30)
                  }}
                  // onClick={() => handleActiveMarker(i)}
                  onClick={() => {
                    handleMarkerClick(user, i);
                  }}
                >
                  {/* {(user._id === selectedMarkerUser) &
                  selectedMarkerUser?._id && ( */}
                  {activeMarker == i && (
                    <InfoWindow
                      options={{ enableEventPropagation: true }}
                      onDomReady={() => setInfoDomReady(true)}
                      onUnmount={() => setInfoDomReady(false)}
                      onCloseClick={() => {
                        handleInfoCloseClick();
                      }}
                    >
                      <div className="infoWindow">
                        {isLoading ? (
                          <p class="my-4">
                            <i class="fa fa-spinner fa-spin fa-2x"></i>
                          </p>
                        ) : (
                          miniProfileData && (
                            <div className="map-profile">
                              {miniProfileData.img ? (
                                <Image
                                  src={miniProfileData.img}
                                  className="banner-img"
                                  onClick={() => history(`/user/${miniProfileData.username}`)}
                                />
                              ) : (
                                <Image
                                  src={require("../../assets/profile-banner.png")}
                                  className="banner-img"
                                  onClick={() => history(`/user/${miniProfileData.username}`)}
                                />
                              )}
                              <span>{miniProfileData.firstname +
                                " " +
                                miniProfileData.lastname}</span>
                              <p>@{miniProfileData?.username}</p>
                              {iconList &&
                                iconList.length &&
                                iconList?.map((icon, i) => {
                                  return (
                                    icon && (
                                      <Image
                                        src={require(`../../assets/${icon.img}`)}
                                        className="roleIcons mr-2"
                                        height={30}
                                        width={30}
                                        title={icon.name}
                                        // src={require("../../assets/Affiliates/Affiliate.svg").default}
                                      />
                                    )
                                  );
                                })}

                              <div className="map-img">
                                <img
                                  src={require('../../assets/sendmessage.svg').default}
                                  onClick={openChat}
                                  className="img-right"
                                />
                                {
                                  miniProfileData.isFriend ? <span
                                  style={{color:'green', fontSize:'18px'}}
                                >
                                  <i class="fa fa-check mr-2"></i>
                                </span>
                                :
                                  miniProfileData.isFriendReqSent ? <span
                                  className="img-right add-friend-btn ml-2"
                                  style={{color: 'white'}}
                                >
                                  <i class="fa fa-users"></i>
                                </span> : <img
                                  src={require('../../assets/addfriend.svg').default}
                                  onClick={ () =>
                                    addFriend(
                                      miniProfileData._id,
                                      miniProfileData.firstname,
                                      miniProfileData.lastname,
                                      miniProfileData.email,
                                      true
                                    )
                                  }
                                  className="img-right2"
                                />
                                }
                                
                                
                               
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </MarkerF>
              );
            })}
        </GoogleMap>
      ) : (
        <></>
      )}
      <div className="d-flex justify-content-end drawer-section">
        <div className="searchSeaction me-5 p-3 pe-5">
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button onClick={submitSearch} className="search-btn">
            Search
          </Button>
          {searchValue && (
            <Button onClick={() => setSearchValue("")}>Clear</Button>
          )}
        </div>
        <label className="p-3 toggel-btn">
          <Switch
            onChange={() => setIsOpen(!isOpen)}
            checked={isOpen}
            offColor="#005197"
            onColor="#005197"
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </label>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="roleDrawer"
        docked={false}
        width={200}
        // style={{'background-color': 'black'}}
      >
        <Container>
          <Row>
            <Col sm={12} className="mt-3 searchRadius ">
              <p style={{'text-align': 'left'}}>Search Radius</p>
              <RangeSlider
                value={rangeValue}
                min={0}
                max={100}
                step={10}
                tooltip="auto"
                  variant='primary'
                tooltipPlacement="top"
                onChange={(changeEvent) =>
                  setRangeValue(changeEvent.target.value)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="d-flex">
              <p style={{'text-align': 'left', 'width': '100%'}}>Switch Current Location</p>
              <Switch
                onChange={switchLocation}
                checked={useCurrentLocation}
                offColor="#800000"
                onColor="#0097ff"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="d-flex">
              <p style={{'text-align': 'left', 'width': '100%'}}>Hide me on map</p>
              <Switch
                onChange={(checked) => {
                  hideOnMap(checked);
                }}
                checked={JSON.parse(localStorage.getItem("user")).hideOnMap}
                offColor="#800000"
                onColor="#0097ff"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </Col>
          </Row>
          <Row className="role-list">
            {categoryWiseRole.map((category, i) => {
              return (
                <Col sm={12} className="mb-3">
                  <span style={{ color: "#C29B0C" }}>{category.name}</span>
                  {category.data.map((role) => {
                    return (
                      <label className="d-flex abc">
                        <span className="mr-2 text-white">{role.name}</span>
                        <Switch
                          onChange={(checked) => {
                            handleRoleChange(role.name, checked);
                          }}
                          checked={
                            JSON.parse(localStorage.getItem("selectedRoles"))?.length
                              ? JSON.parse(localStorage.getItem("selectedRoles")).findIndex((x) => x == role.name) !== -1 ? true : false
                              : false
                          }
                          offColor="#800000"
                          onColor="#0097ff"
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      </label>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
        </Container>
      </Drawer>
      {
        isChatOpen &&  
          <ChatPopuup friend={miniProfile} close={setIsChatOpen}/>
      }
     
    </div>
  );
};
