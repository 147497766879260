import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Bar } from "react-chartjs-2";
import { Container, Row, Col, Form, Image, Button, Card } from "react-bootstrap";

import { getStatisticsData } from "../../toolkit/statisticsSlice";
import socketIO from 'socket.io-client';

import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";

import './statistics.scss';

Chart.register(CategoryScale);
export const Statistics = () => {
  const dispatch = useDispatch();
  const socketRef = useRef()


  const socket = socketIO(process.env.SOCKET_URL, {transports: ['websocket']});
  // const socket = io('http://localhost:443', { autoConnect: true});

  const isLoading = useSelector((state) => state.statistics.isLoading);
  const statistics = useSelector((state) => state.statistics.statistics);
  const [ onlineUsers, setOnlineUsers] = useState(0);
  const [chartLabel, setChartLabel] = useState("");
  const [chartValues, setChartValues] = useState({});
  const [ currentLink, setCurrentLink ] = useState(null);
  
  useEffect(() => {
    socket.connect();
   console.log('------socket', socket)
   socket.emit("get_online_users");
   socket.on('get_online_ids', (data) => {
    setOnlineUsers(data)
   });
  }, [socket]);
  useEffect(() => {
    dispatch(getStatisticsData());
  }, [dispatch]);
  const setChartData = (type) => {
    if (type == "registred") {
      setChartValues(statistics.regsiteredAccounts);
      setChartLabel("User Profiles");
    } else if (type == "friends") {
      setChartValues(statistics.totalFriendsConnection);
      setChartLabel("Friends Connections");
    } else if (type == "subscriber") {
      setChartValues(statistics.totalSubscribers);
      setChartLabel("Subscribtion Connections");
    } else if (type == "wallet") {
      setChartValues(statistics.xummCount);
      setChartLabel("Wallet Connections");
    } else if (type == "connections") {
      setChartValues(statistics.totalConnections);
      setChartLabel("Total Connections");
    } else if (type == "countries") {
      setChartValues(statistics.totalCountries);
      setChartLabel("Countries");
    }
    if (type == "entity") {
      setChartValues(statistics.totalEntity);
      setChartLabel("Entities");
    }
    if (type == "project") {
      setChartValues(statistics.totalProjects);
      setChartLabel("Projects");
    }
    setCurrentLink(type);
  };
  useEffect(() => {
    if(Object.keys(statistics).length)
      setChartValues(statistics.regsiteredAccounts);
      setChartLabel("User Profiles");
  }, [statistics])
  
  return (
    <div className="statistics-page">
      {isLoading ? (
        <Image
          src={require("../../assets/Preloader.gif")}
          className="logo-img"
        />
      ) : (
        <Container className="text-white mt-4">
           <div className="d-block d-md-none">

            <div className="mb-2 head-title">
              <div>Statistics</div>
              <span class="divider1 mb-4"></span>
            </div>
            </div>
          <Row>
            <Col md={3}>
              <Card className="py-2 px-2 stat-box">
              <div id="registred"  className={currentLink == 'registred' ? 'current-link value-row mt-3' : 'mt-3 value-row'} onClick={() => setChartData('registred')}>
                <p className="">User Profiles</p>
                <p className="count-value">
                  {statistics?.regsiteredAccounts?.totalRegisteredAccounts}
                  </p>
              </div>
          <div id="entity" className={currentLink == 'entity' ? 'current-link value-row' : ' value-row'} onClick={() => setChartData('entity')}>
              <p>Entities</p>
              <p className="count-value"> 
              {statistics?.totalEntity?.totalentityCount}
              </p>
          </div>
          <div id="project" class={currentLink == 'project' ? 'current-link value-row' : ' value-row'} onClick={() =>setChartData('project')}>
            <p>Projects</p>
              <p className="count-value">
                 {statistics?.totalProjects?.totalProjectsCount}
              </p>
          </div>
          <div id="friends" className={currentLink == 'friends' ? 'current-link value-row' : 'value-row'} onClick={() => setChartData('friends')}>
              <p>Friends Connection</p>
              <p className="count-value">
                {statistics?.totalFriendsConnection?.totalFriendsCount}
              </p>
          </div>
          <div id="subscriber"  className={currentLink == 'subscriber' ? 'current-link value-row' : 'value-row'} onClick={() => setChartData('subscriber')}>
              <p>Subscriber Connection</p>
              <p class="count-value">
              {statistics?.totalSubscribers?.totalSubscriberCount}
              </p>
          </div>
          <div id="connections" className={currentLink == 'connections' ? 'current-link value-row' : 'value-row'} onClick={() => setChartData('connections')}>
            <p>Total Connection</p>
              <p className="count-value"> 
              {statistics?.totalConnections?.total}
              </p>
          </div>
           <div id="countries" class={currentLink == 'countries' ? 'current-link value-row' : 'value-row'} onClick={() =>setChartData('countries')} >
            <p>Total Countries</p>
              <p className="count-value"> 
              {statistics?.totalCountries?.total}
              </p>
          </div>
          <div id="wallet" className={currentLink == 'wallet' ? 'current-link value-row' : ' value-row'} onClick={() =>setChartData('wallet')}>
            <p>Total Wallet</p>
              <p className="count-value">
              {statistics?.xummCount?.totalWalletCount}
              </p>
          </div>
          <div className="value-row">
            <p>Users Online</p>
              <p className="count-value"> 
              {onlineUsers.length}
              </p>
          </div>
              </Card>
            </Col>
            <Col md={6}>
              <div className="d-none d-md-block">
              <div className="mb-2 head-title">
                <div>Statistics</div>
                  <span className="divider1 mb-4"></span>
                </div>
              </div>
              <div className="chart-container"> 
              <Bar
                data={{
                  labels: chartValues?.data?.labels,
                  datasets: [
                    {
                      label: chartLabel,
                      data: chartValues?.data?.data,
                      backgroundColor: [
                        "#ff4d1d",
                        "#ff4d1d",
                        "#ff4d1d",
                        "#ff4d1d",
                      ],
                      borderWidth: 0.1,
                    },
                  ],
                }}
                height={100}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                  legend: {
                    labels: {
                      fontSize: 15,
                    },
                  },
                }}
              />
              </div>
            </Col>
            <Col md={3}>
              <Container className="text-start pl-lg-5 pl-md-4 entertainer-list">
              <h2 className="list-head mt-2">Entertainers</h2>
              <div className="mt-5">
                {statistics?.rolesCount?.map((x) => {
                  return (
                    <p className="mb-0">
                      {" "}
                      {x._id}: {x.count}
                    </p>
                  );
                })}
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
