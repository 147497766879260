import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, updateProfilePicture, updateCoverPicture } from "../../toolkit/usersSlice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { successMessage, warningMessage } from "../../toolkit/toastSlice";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./editProfileContainer.scss";

const EditProfileComponent = (props) => {
  const { profileData } = props;
  console.log(profileData._id, 'profileData')
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({ defaultValues: {} });
  const updateProfileMessage = useSelector(
    (state) => state.user.updateProfileMessage
  );

  const userError = useSelector((state) => state.user.userError);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const isLoading = useSelector((state) => state.user.isLoading);
  useEffect(() => {
    if (updateProfileMessage === "Profile updated successfully!") {
      dispatch(successMessage(updateProfileMessage));
      const id =
        JSON.parse(localStorage.getItem("user"))._id ||
        JSON.parse(localStorage.getItem("user")).id;
      navigate(`/profile/${id}`);
    }
  }, [dispatch, navigate, updateProfileMessage]);

  useEffect(() => {
    if (userError) dispatch(warningMessage(userError));
  }, [userError]);

  useEffect(() => {
    if (profileData) {
      reset(profileData);

      setCountry(profileData.country);
      setRegion(profileData.region);
      setValue("country", profileData.country);
      setValue("region", profileData.region);
      setValue("firstname", profileData.firstname);
      setValue("lastname", profileData.lastname);
      setValue("username", profileData.username);
      setValue("customRole", profileData.customRole);
      setValue("about", profileData.about);
    }
  }, [profileData, setValue]);
  const [profile, setProfile] = useState("");
  const [coverImage, setCoverImage] = useState("");

  // Submit form
  const editUser = (user) => {
    if (user.role) {
      user.role = user.role.map((r) => (typeof r === "object" ? r.value : r));
    }
    console.log(profile, 'profile')
    dispatch(
      updateProfile(JSON.parse(localStorage.getItem("user")).username, {
        ...user,
        img: coverImage,
        profile
      })
    );
  };

  // const [profile, setProfile] = useState("");

  const pickProfile = (e) => {
    let input = e.target;
    let file = input.files;
    if (file && file[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setProfile(e.target.result);
      };
      reader.readAsDataURL(file[0]);
    }
    const formData = new FormData();
    let exten = e.target.files[0].name.split(".")[1]
    const filName = `${profileData._id}.${exten}`;
    formData.append(`file`, e.target.files[0]);
    formData.append(`userId`, profileData._id);
    formData.append(`fileName`, filName);

    try {
      updateProfilePicture(formData).then((res) => {
        setProfile(res.data)
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const pickCoverImage = (e) => {
    let input = e.target;
    let file = input.files;
    if (file && file[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCoverImage(e.target.result);
      };
      reader.readAsDataURL(file[0]);
      // this.photo = file[0];
    }

    const formData = new FormData();
    let exten = e.target.files[0].name.split(".")[1]
    const filName = `${profileData._id}.${exten}`;
    formData.append(`file`, e.target.files[0]);
    formData.append(`userId`, profileData._id);
    formData.append(`fileName`, filName);
    try {
      updateCoverPicture(formData).then((res) => {
        setCoverImage(res.data)
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Container style={{ marginTop: "2rem" }} className="edit-profile-container">
      {/* Banner */}
      {/* <Row className="mb-3" style={{ backgroundColor: '#f8f9fa', position: 'relative', borderRadius: '5px', padding: '20px 0' }}>
        <Col xs={12} md={3} className="text-center">
          <div style={{ position: 'relative', marginBottom: '10px' }}>
            <Image src={profileData.profile} roundedCircle style={{ width: '120px', height: '120px' }} />
            <FontAwesomeIcon icon={faEdit} style={{ position: 'absolute', bottom: '5px', right: '5px', cursor: 'pointer', backgroundColor: 'white', borderRadius: '50%' }} />
          </div>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <h3>Edit Profile</h3>
          <Button variant="light" style={{ border: '1px solid black', borderRadius: '5px' }}>
            Delete Profile <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Col>
      </Row> */}

      <Row className="mb-4 position-relative">
        <Col className="text-center">
          <Image
            src={profile || profileData.profile}
            className="profile-image"
            roundedCircle
            fluid
          />
          <Form.Control
            type="file"
            id="profileUpload"
            hidden
            required
            className="box-line"
            onChange={pickProfile}
          />
          <Button
            className="edit-icon-profile"
            variant="light"
            onClick={() => {
              document.getElementById("profileUpload").click();
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="position-relative">
          <Image src={coverImage || profileData.img} fluid />
          <Form.Control
            type="file"
            id="imgUpload"
            hidden
            required
            className="box-line"
            onChange={pickCoverImage}
          />
          <Button className="edit-icon-cover" variant="light" onClick={() => {
              document.getElementById("imgUpload").click();
            }}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </Col>
      </Row>
      <Row className="mb-4" style={{ flexDirection: "row-reverse" }}>
        <Button
          variant="light"
          style={{
            width: "10rem",
            border: "1px solid #C29B0C",
            borderRadius: "5px",
            backgroundColor: "transparent",
            color: "#C29B0C",
          }}
        >
          Delete Profile <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Row>
      {/* Location Field */}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col xs={12} md={12} className="d-flex">
            <Form.Group controlId="formCity" style={{ width: "80%" }}>
              <Form.Control
                className="fields"
                type="text"
                placeholder="Enter city"
              />
            </Form.Group>
            <Button
              variant="light"
              className="update-location"
              style={{ marginLeft: "10px" }}
            >
              Update Location
            </Button>
          </Col>
        </Row>

        {/* Other Fields */}
        <Row className="mb-3">
          <Col xs={12} md={12}>
            <Form.Group controlId="formUsername">
              <Form.Control
                className="fields"
                type="text"
                placeholder={profileData.username || "Enter your @username"}
                defaultValue={profileData.username || ""}
                {...register("username", { required: true })}
              />
            </Form.Group>
            {errors.username && (
              <span className="error">{"Username is required"}</span>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group controlId="formFirstName">
              <Form.Control
                className="fields"
                type="text"
                placeholder={profileData.firstname || "Enter your First Name"}
                defaultValue={profileData.firstname || ""}
                {...register("firstname", { required: true })}
              />
              {errors.firstname && (
                <span className="error">{"Firstname is Required"}</span>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formLastName">
              <Form.Control
                className="fields"
                type="text"
                placeholder={profileData.lastname || "Enter your Last Name"}
                defaultValue={profileData.lastname || ""}
                {...register("lastname", { required: true })}
              />
            </Form.Group>
            {errors.lastname && (
              <span className="error">{"Lastname is required"}</span>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={12}>
            <Form.Group controlId="formCustomRole">
              <Form.Control
                className="fields"
                type="text"
                placeholder={
                  profileData.customRole || "Type exactly what you are"
                }
                defaultValue={profileData.customRole || ""}
                {...register("customRole", { required: false })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group controlId="formRegion">
              {/* <Form.Control
                className="fields"
                type="text"
                placeholder={profileData.region || "Enter your Region"}
                value={profileData.region || ""}
              /> */}
              <RegionDropdown
                country={country}
                value={region}
                onChange={(value) => {
                  setValue("region", value);
                  setRegion(value);
                }}
                className="fields form-control"
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formCountry">
              <CountryDropdown
                value={country}
                countryValueType="short"
                onChange={(value) => {
                  setValue("country", value);
                  setCountry(value);
                }}
                className="fields form-control"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={12}>
            <Form.Group controlId="formAbout">
              <Form.Control
                className="fields"
                as="textarea"
                rows={3}
                placeholder="Enter about"
                defaultValue={profileData.about}
                {...register("about", { required: false })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} md={12} className="update-footer">
            <Button
              variant="light"
              className={
                isLoading
                  ? "opacity-loading update-location"
                  : "update-location"
              }
              type="submit"
              onClick={handleSubmit(editUser)}
            >
              Update Profile
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default EditProfileComponent;
