import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import "./userYouMightLike.scss"
const userList = [
  { id: 1, profilePic: 'https://shockwave-posts.nyc3.digitaloceanspaces.com/profile-pic/62b5b728df93ce0016e9fd06/62b5b728df93ce0016e9fd06.png', name: 'John Doe', username: 'johndoe' },
  { id: 2, profilePic: 'https://shockwave-posts.nyc3.digitaloceanspaces.com/profile-pic/62b5b728df93ce0016e9fd06/62b5b728df93ce0016e9fd06.png', name: 'Jane Smith', username: 'janesmith' },
  { id: 3, profilePic: 'https://shockwave-posts.nyc3.digitaloceanspaces.com/profile-pic/62b5b728df93ce0016e9fd06/62b5b728df93ce0016e9fd06.png', name: 'Alice Johnson', username: 'alicejohnson' }
];

const UserYouMightLike = () => {
  return (
    <Container className='user-list-container'>
      {userList.map(user => (
        <Row key={user.id} className="mb-3">
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={3}>
                    <img src={user.profilePic} alt="Profile Pic" style={{ width: '50px', borderRadius: '50%' }} />
                  </Col>
                  <Col>
                    <Card.Title className='text-white'>{user.name}</Card.Title>
                    <Card.Text className='text-white-opacity'>@{user.username}</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default UserYouMightLike;
