import React, { useEffect, useState } from "react";
import moment from 'moment';
import {
  onSnapshot,
  getDoc,
  doc,
  setDoc,
  arrayRemove,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../firestore/db";
import {
  Col,
  Container,
  Row,
  Image,
  Navbar,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import ChatWindow from "./ChatWindow";

import "./friends.scss";
import {
  getFriendsData,
  getUserFriendsRequestData,
  updateFriendReqStatus,
} from "../../toolkit/friendsSlice";
import { errorMessage } from "../../toolkit/toastSlice";
import socketIO from "socket.io-client";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const Firends = () => {
  const socket = socketIO(process.env.SOCKET_URL, {
    transports: ["websocket"],
  });

  const isLoading = useSelector((state) => state.friends.isLoading);
  const friendsError = useSelector((state) => state.friends.userError);
  const friendsData = useSelector((state) => state.friends.friendsData);
  const userFriendRequests = useSelector(
    (state) => state.friends.userFriendRequests
  );
  const sucessMessage = useSelector((state) => state.friends.sucessMessage);
  const rolesIconData = useSelector((state) => state.roles.rolesIcon);
  const dispatch = useDispatch();

  const [searchData, setSearchData] = useState();
  const [selectedUser, setSelectedUser] = useState(friendsData[0]);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    socket.connect();
    socket.emit("get_online_users");
    socket.on("get_online_ids", (data) => {
      setOnlineUsers(data);
    });
  }, [socket]);

  React.useEffect(() => {
    dispatch(getFriendsData(JSON.parse(localStorage.getItem("user")).id));
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(
      getUserFriendsRequestData(JSON.parse(localStorage.getItem("user")).id)
    );
  }, [dispatch]);
  React.useEffect(() => {
    if (friendsData.length) {
      if (friendsData.length) {
        let data = friendsData.filter((u) => u.friendId !== "");
        let newData = []
        friendsData.forEach(async (friend, i) => {
          const id = JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id
          const chatHash = [id, friend.friendId].sort().join();
          onSnapshot(doc(db, "logs", chatHash), async (doc) => {
            let dbData = doc.data();
            let index = data.findIndex(
              (f) => f._id == friend._id
            );
            console.log('...', data[index])

            if (!dbData?.messages?.length) {
              // data[index].message = [];
              newData.push({...data[index], message: []})
            } else {
              // data[index].message = dbData.messages[dbData.messages.length - 1].payload;
              // data[index].time = dbData.messages[dbData.messages.length - 1].time;
              newData.push({...data[index], message: dbData.messages[dbData.messages.length - 1].payload, time: dbData.messages[dbData.messages.length - 1].time})

            }
            if (i == newData.length - 1) {
              newData = newData.sort((f1, f2) => {
                let f1LastMessageTime = f1.time ? f1.time : "0";
                let f2LastMessageTime = f2.time ? f2.time : "0";
                return (
                  Number(moment(f2LastMessageTime)) -
                  Number(moment(f1LastMessageTime))
                );
              });
            
              setSearchData(newData);
              setSelectedUser(newData[0]);
            }
          });
        });
      } else {
        // friendsLoading = false;
      }     
    }
  }, [friendsData]);
  const getRoleIcons = (roles) => {
    let roleIcons = [];
    roles.map((r) => {
      roleIcons.push(
        rolesIconData.find((i) => {
          if (i.name === r) return r;
        })
      );
    });
    return roleIcons;
  };
  const acceptRequest = (record) => {
    record = { ...record, friend_req_status: "Accepted" };
    dispatch(updateFriendReqStatus(record));
  };
  const rejectRequest = (record) => {
    record = { ...record, friend_req_status: "Rejected" };
    dispatch(updateFriendReqStatus(record));
  };
  useEffect(() => {
    if (sucessMessage?.length) {
      dispatch(getFriendsData(JSON.parse(localStorage.getItem("user")).id));

      dispatch(
        getUserFriendsRequestData(JSON.parse(localStorage.getItem("user")).id)
      );
    }
  }, [sucessMessage]);

  const onSearchFriends = (e) => {
    let temp = [];
    const { value } = e.target;
    if (value) {
      friendsData.forEach((fr) => {
        if (
          fr.firstname.toLowerCase().includes(value.toLowerCase()) ||
          fr.lastname.toLowerCase().includes(value.toLowerCase())
        ) {
          temp.push(fr);
        }
      });
      setSearchData(temp);
    } else if (!value) {
      setSearchData(friendsData);
    }
  };
  const copyUrl = async () => {
    try {
      await navigator.clipboard.writeText(
        `Come join SHOCKWAVE, The Entertainment Platform where entertainers, affiliates and content consumers connect. Register at https://shockwave.network/"`
      );
      var tooltip = document.getElementsByClassName("tooltiptext")[0];
      tooltip.innerHTML = "Copied";
      dispatch(sucessMessage("Copied"));
    } catch ($e) {
      dispatch(errorMessage("Can not copy"));
    }
  };
  const [shareDetail, setShareDetails] = useState({});
  useEffect(() => {
    const sharingTitle =
      "Come join SHOCKWAVE, The Entertainment Platform where entertainers, affiliates and content consumers connect. Register at https://shockwave.network/";
    setShareDetails({ sharingTitle });
  }, []);
  return (
    <div className="friends-page">
      <Container className="p-0">
        <Row className="d-none d-md-flex mt-4">
          <Col lg={3}>
            <Container id="incoming_request">
              {userFriendRequests?.map((request) => {
                return (
                  <Row>
                    <Col sm={4} className="mt-3">
                      {request.firstname + " " + request.lastname}
                    </Col>
                    <Col sm={8} className="p-2 d-flex">
                      <button
                        className="me-2"
                        onClick={() => acceptRequest(request)}
                      >
                        Accept
                      </button>
                      <button
                        className="me-2"
                        onClick={() => rejectRequest(request)}
                      >
                        Reject
                      </button>
                    </Col>
                  </Row>
                );
              })}
            </Container>
            <div className="justify-content-center p-2 py-4">
              <div className="d-flex align-items-center justify-content-center">
                <div className="search"></div>
                <div className="searchIcon text-end search-icon-containor">
                  <span className="fa fa-search search-icon"></span>
                </div>

                <input
                  placeholder="Search for messages of user..."
                  name="search"
                  id="search"
                  className="form-control search-bar"
                  type="text"
                  onChange={onSearchFriends}
                />
              </div>
            </div>
            <Container className="mt-2">
              {searchData?.length ? (
                searchData.map((record, i) => {
                  return (
                    <div className="friends-info-block">
                      <Row>
                        <Col sm={6} className="justify-content-start">
                          {onlineUsers?.includes(record.friendId) ? (
                            <div className="online-button m-3"></div>
                          ) : (
                            <div className="offline-button m-3"></div>
                          )}
                          <div></div>
                        </Col>
                        <Col sm={6} className="justify-content-end">
                          {getRoleIcons(record.role)?.map((icon) => {
                            return (
                              <span>
                                <Image
                                  src={
                                    require(`../../assets/${icon.img}`)
                                  }
                                  className="icon-img me-2"
                                />
                              </span>
                            );
                          })}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0">
                          {record.profile ? (
                            <Image
                              src={record.profile}
                              className="friend-profile"
                            />
                          ) : (
                            <Image
                              src={require("../../assets/users.svg").default}
                              className="friend-profile"
                            />
                          )}
                        </Col>
                        <Col className="p-0">
                          <div className="username text-start">
                            {record.firstname + " " + record.lastname}
                          </div>
                          {record.role.length && (
                            <div className="desc-role">{record.role[0]}</div>
                          )}
                        </Col>
                        <Col className="p-0">
                          <button
                            className="message-btn"
                            onClick={() => setSelectedUser(record)}
                          >
                            Message
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="incoming-count ms-2">0</div>
                        </Col>
                        <Col className="text-end me-2">
                          <img
                            src={
                              require(`../../assets/double-tick.svg`).default
                            }
                            class="icon-img"
                          />
                          <span className="read-time">{moment(record.time).format("HH:mm")}</span>
                        </Col>
                      </Row>
                    </div>
                  );
                })
              ) : (
                <p>No Friends record found</p>
              )}
            </Container>
          </Col>
          <Col lg={6} className="p-0">
              <ChatWindow friend={selectedUser} />
            
          </Col>
          <Col lg={3}>
            <Dropdown align="end">
              <Dropdown.Toggle variant="dark" id="dropdown-basic">
                <div className="invite-btn">Invite Friends</div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="d-flex media-icon">
              <Dropdown.Item className="">
                <TwitterShareButton url={shareDetail.sharingTitle} >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <FacebookShareButton url={shareDetail.sharingTitle}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <LinkedinShareButton url={shareDetail.sharingTitle}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <WhatsappShareButton url={shareDetail.sharingTitle}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <button onClick={copyUrl} className="copy-btn">copy</button>
                <div className="tooltip">
                  <span className="tooltiptext">Copy to clipboard</span>
                </div>
              </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Firends;
