import {axiosInstance} from '../services';

export async function getPosts(userId) {
    return axiosInstance.get(`/post/all/${userId}`);
}
export async function getUserFriend(id) {
    return axiosInstance.get(`/friends/getFriends/${id}`);
}
export async function addNewPost(payload) {
  return axiosInstance.post("/post/", payload);
}

export async function addNewPostAttachment(payload) {
  return axiosInstance.post('/post/upload/', payload)
}

export async function deletePost(id) {
  return axiosInstance.delete(`/post/${id}`)
}

export async function addToNotInterestedPost({ userId, postId }) {
  return axiosInstance.post(`/post/addToNotInterested/${postId}`, { userId: userId })
}

export async function addToFav({userId, postId}) {
    return axiosInstance.post(`/post/addToFav/${postId}`, {userId: userId});
  }
  
  export async function removeFromFav({userId, postId}) {
    return axiosInstance.post(`/post/removeFromFav/${postId}`, {userId: userId});
  }
  
  export async function addToLikes({userId, postId}) {
    return axiosInstance.post(`/post/addToLikes/${postId}`, {userId: userId});
  }
  
  export async function removeFromLikes({userId, postId}) {
    return axiosInstance.post(`/post/removeFromLikes/${postId}`, {userId: userId});
  }
