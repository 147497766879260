import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const rolesSlice = createSlice({
  name: "toast",
  initialState: {
    toastMessage: "",
  },
  reducers: {
    success: (state, action) => {
      state.toastMessage = action.payload;
    },
    warning: (state, action) => {
      state.toastMessage = action.payload;
    },
    error: (state, action) => {
      state.toastMessage = action.payload;
    },
  },
});

export const { success, warning, error } = rolesSlice.actions;

export const successMessage = (payload) => async (dispatch) => {
  dispatch(success(payload));
  toast.success(payload);
};

export const warningMessage = (payload) => async (dispatch) => {
  dispatch(warning(payload));
  toast.warning(payload);
};
export const errorMessage = (payload) => async (dispatch) => {
  dispatch(error(payload));
  toast.error(payload);
};
export default rolesSlice.reducer;
