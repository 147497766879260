import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
// import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { addBug, addSuggestion } from "../../toolkit/bugSlice";
import QuillBox from "./quillbox";
import "./bugSystem.css";

const BugSystem = () => {
  const [suggestionsError, setSuggestionsError] = useState({
    comment: false,
  });

  const [selectedOption, setSelectedOption] = useState("bug");

  const [comment, setComment] = useState("");
  const [selectedPage, setSelectedPage] = useState("");
  const [bugError, setBugError] = useState({ page: false, comment: false });

  let pages = [
    { text: "Profile", value: "Profile" },
    { text: "Home", value: "Home" },
    { text: "Map", value: "Map" },
    { text: "Friends", value: "Friends" },
    { text: "Projects", value: "Projects" },
    { text: "Player", value: "Player" },
    { text: "Payment", value: "Payment" },
    { text: "Wallet", value: "Wallet" },
    { text: "Statistics", value: "Statistics" },
    { text: "Bug and Reporting system", value: "Bug and Reporting system" },
  ];

  const addBugData = () => {
    // Reset errors
    setBugError({ page: false, comment: false });
    let strippedHtml = comment.replace(/<[^>]+>/g, "");

    // Your validation logic
    if (!selectedPage || !strippedHtml) {
      setBugError({
        page: !selectedPage,
        comment: !strippedHtml,
      });
      return;
    }

    const formData = new FormData();
    // formData.append("commentImg", commentImg);
    formData.append("page", selectedPage);
    formData.append("comment", comment);
    formData.append("userId", JSON.parse(localStorage.getItem("user")).id);
    formData.append("email", JSON.parse(localStorage.getItem("user")).email);

    // Assuming addBug is an asynchronous function that returns a promise
    addBug(formData)
      .then((response) => {
        if (response.data.message) {
          debugger;
          setSelectedPage("");
          setComment("");
          setBugError({ page: false, comment: false });
        }
      })
      .catch((e) => {});
  };

  const [suggestionComment, setSuggestioncomment] = useState("");
  const [selectedPageSuggestion, setSelectedPageSuggestion] = useState("");
  const [bugErrorSuggestion, setBugErrorSuggestion] = useState({
    page: false,
    comment: false,
  });

  const addSuggestionsData = () => {
    setBugErrorSuggestion({ page: false, comment: false });
    let strippedHtml = suggestionComment.replace(/<[^>]+>/g, "");

    // Your validation logic
    if (!selectedPage || !strippedHtml) {
      setBugErrorSuggestion({
        page: !selectedPage,
        comment: !strippedHtml,
      });
      return;
    }

    const formData = new FormData();
    // formData.append("commentImg", commentImg);
    formData.append("page", selectedPageSuggestion);
    formData.append("comment", suggestionComment);
    formData.append("userId", JSON.parse(localStorage.getItem("user")).id);
    formData.append("email", JSON.parse(localStorage.getItem("user")).email);

    addSuggestion(formData)
      .then((response) => {
        if (response.data.message) {
          debugger;
          setSelectedPageSuggestion("");
          setSuggestioncomment("");
          setBugError({ page: false, comment: false });
        }
      })
      .catch((e) => {});
  };

  return (
    <div className="main-content">
      <div className="heading">
        <p>Reports</p>
      </div>
      <div className="m-5">
        <div className="d-flex justify-content-center">
          <button
            className={`  ${
              selectedOption === "bug" ? "btn  mr-5 btn-menu active-menu" : "btn btn-primary mr-5 btn-menu"
            }`}
            onClick={() => setSelectedOption("bug")}
          >
            Bug
          </button>
          <button
           className={` margin ${
            selectedOption === "suggestion" ? "btn  mr-5 btn-menu active-menu" : "btn btn-primary mr-5 btn-menu"
          }`}
            onClick={() => setSelectedOption("suggestion")}
          >
            Suggestion
          </button>
        </div>
      </div>
      <div className="row m-5 d-flex justify-content-center">
        <div className="col-md-10">
          <div>
            {selectedOption === "bug" ? (
              <div className="input-area">
                <div>
                  <Form.Group controlId="input-group-3">
                    <Form.Control
                      as="select"
                      className="page-select margin-15"
                      value={selectedPage}
                      onChange={(e) => setSelectedPage(e.target.value)}
                      style={{ background: "#6c6c6c" }}
                    >
                      {pages.map((page) => (
                        <option key={page.value} value={page.value}>
                          {page.text}
                        </option>
                      ))}
                    </Form.Control>
                    {bugError.page && (
                      <span className="err">Page is required</span>
                    )}
                  </Form.Group>
                </div>
                <QuillBox comment={comment} setComment={setComment} />
              </div>
            ) : null}

            {selectedOption === "suggestion" ? (
              <div className="input-area">
                <Form.Group controlId="input-group-3">
                  <Form.Control
                    as="select"
                    className="page-select margin-15"
                    value={selectedPage}
                    onChange={(e) => setSelectedPage(e.target.value)}
                    style={{ background: "#6c6c6c" }}
                  >
                    {pages.map((page) => (
                      <option key={page.value} value={page.value}>
                        {page.text}
                      </option>
                    ))}
                  </Form.Control>
                  {bugError.page && (
                    <span className="err">Page is required</span>
                  )}
                </Form.Group>
                <QuillBox
                   
                  comment={suggestionComment}
                  setComment={setSuggestioncomment}
                />
              </div>
            ) : null}

            <span className="err">
              {selectedOption === "bug" && bugError.comment
                ? bugError.comment
                : selectedOption === "suggestion" && suggestionsError.comment
                ? suggestionsError.comment
                : null}
            </span>

            {selectedOption && (
              <Button
                className="btn btn-primary mt-3 btn-submit"
                onClick={() =>
                  selectedOption === "bug" ? addBugData() : addSuggestionsData()
                }
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BugSystem;
