import { createSlice,current  } from "@reduxjs/toolkit";
import {axiosInstance} from '../services';

export const NFTSlice = createSlice({
  name: "NFTSlice",
  initialState: {
    xummData: [],
    // reducers: {
    //     setLoading: (state, action) => {
    //       // state.isLoading = action.payload
    //     }
    // },
  }
});

export const connectAccount = (id) => {
  return axiosInstance.post(`/xumm/connect/${id}`);
}

export const getXummData = (id) => {
  return axiosInstance.get(`/xumm/account/${id}`);
}

export const deleteAccount = (id) => {
  return axiosInstance.get(`/xumm/delete/${id}`);
}

export default NFTSlice.reducer;

