import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { ThemeContext } from "../../theme"
import "./layout.scss";

const SidebarCol = () => {
  const navigate = useNavigate();
  const [toggle, handleToggle] = useState(false);
  
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };
  const locationofcomponent = useLocation();
      
    
  const { theme, toggleTheme} = useContext(ThemeContext)
   console.log("Current pathname:", locationofcomponent.pathname);
  return (
    <div className="sidebar">
      <Sidebar width="200px" collapsed={toggle}>
        <Menu
          menuItemStyles={{
            button: {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              [`&.focus`]: {
                backgroundColor: "#ff4d1d",
                color: "#fff",
              },
            },
          }}
        >
          <MenuItem
            component={<Link to="/home" />}
            icon={<Image src={require("../../assets/Menu-Home.svg").default} />}
            className={locationofcomponent.pathname === "/home" ? "active-menu": ''}
          >
            Home
          </MenuItem>
          <MenuItem
            component={<Link to="/feed" />}
            icon={<Image src={require("../../assets/FeedIcon.svg").default} />}
            className={locationofcomponent.pathname === "/feed" ? "active-menu": ''}

          >
            Feed
          </MenuItem>
          <MenuItem
            component={<Link to="/map" />}
            icon={<Image src={require("../../assets/Menu-Map.svg").default} />}
            className={locationofcomponent.pathname === "/map" ? "active-menu": ''}

          >
            Map
          </MenuItem>
          <MenuItem
            component={<Link to="/friends" />}
            icon={
              <Image src={require("../../assets/Menu-Friends.svg").default} />}
            className={locationofcomponent.pathname === "/friends" ? "active-menu": ''}

          >
            Friends
          </MenuItem>
          <MenuItem
            component={<Link to="/content" />}
            icon={<Image src={require("../../assets/Menu-Play.svg").default} />}
            className={locationofcomponent.pathname === "/content" ? "active-menu": ''}

          >
            Content
          </MenuItem>
          <MenuItem
            component={<Link to="/event" />}
            icon={<Image src={require("../../assets/EventIcon.svg").default} />}
            className={locationofcomponent.pathname === "/event" ? "active-menu": ''}

          >
            Event
          </MenuItem>
          <MenuItem
            component={<Link to="/create" />}
            icon={
              <Image src={require("../../assets/Menu-Create.svg").default} />}
            className={locationofcomponent.pathname === "/create" ? "active-menu": ''}

          >
            Create
          </MenuItem>
          <MenuItem
            component={<Link to="/market" />}
            icon={
              <Image src={require("../../assets/Menu-Market.svg").default} />}
            className={locationofcomponent.pathname === "/market" ? "active-menu": ''}

          >
            Market
          </MenuItem>
          <MenuItem
            component={<Link to="/wallet" />}
            icon={
              <Image src={require("../../assets/Menu-Wallet.svg").default} />}
            // className={locationofcomponent.pathname === "/home" ? "active-menu": ''}

          >
            Wallet
          </MenuItem>

          <SubMenu
            label="Settings"
            icon={
              <Image src={require("../../assets/Menu-Settings.svg").default} />}
            

          >
            <MenuItem>Enable training mode</MenuItem>
            <MenuItem onClick={() => toggleTheme()}>{theme}</MenuItem>
            <MenuItem> Premium subsciption</MenuItem>
            <MenuItem component={<Link to="/statistics" />}> Statistics</MenuItem>
            <MenuItem component={<Link to="/terms" />}>Terms & Conditions</MenuItem>
            <MenuItem> Bug / Suggestion</MenuItem>
            <MenuItem
              component={
                <Link
                  to="https://xrpl.services/?issuer=rKk7mu1dNB25fsPEJ4quoQd5B8QmaxewKi&currency=CX1&limit=24993568092.41018"
                  target="_blank"
                />
              }
            >
              {" "}
              Set CX1 trustline
            </MenuItem>
            <MenuItem
              component={
                <Link
                  to="https://xrpl.services/?issuer=rpF7w6NYZtvJWvBYYEuNFZ4iLR7mR1MkS9&currency=5741564500000000000000000000000000000000&limit=12500000000"
                  target="_blank"
                />
              }
            >
              {" "}
              Set WAVE trustline
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </SubMenu>
          <MenuItem
            icon={
              <i
                class={`fa ${toggle ? "fa-arrow-right" : "fa-times"}`}
                onClick={() => handleToggle(!toggle)}
              ></i>
            }
          ></MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarCol;
