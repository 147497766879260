import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: "https://shockwave.network",
  // baseURL: 'http://199.192.16.107:443'
  // baseURL: "http://localhost:443",
  // baseURL: "http://localhost:4000",


});

axiosInstance.interceptors.request.use(function (config) {
    config = {...config, params: {
        ...config.params,
    }};
    config.headers['authorization'] = `Bearer ` + localStorage.getItem('accessToken');
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);