import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Image } from "react-bootstrap";
import {
  getPosts,
  getUserFriend,
  addToFav,
  removeFromFav,
  addToLikes,
  removeFromLikes,
  deletePost,
  addToNotInterestedPost,
} from "../../toolkit/postSlice";
import "./feed.scss";
import { toast } from "react-toastify";
import myImage from "../../assets/more.png";
import likesicon from "../../assets/likes.svg";
import likesfill from "../../assets/likes-fill.svg";
import favouriteIcon from "../../assets/favourites.svg";
import favouriteFillIcon from "../../assets/favourites-fill.svg";
import PostForm from "./post";
import commenticon from "../../assets/comments.svg";
import reposticon from "../../assets/repost.svg";
import shareicon from "../../assets/share.svg";
import PostCreationBox from "./facebookPostCreation"

const rolesIcon = [
  { img: "Entertainers/users.svg", name: "Viewer/Listener/User" },
  { img: "Entertainers/Actor.svg", name: "Actor" },
  { img: "Entertainers/Filmmaker.svg", name: "Filmmaker" },
  { img: "Affiliates/Affiliate.svg", name: "Affiliate" },
  { img: "Entertainers/Athlete.svg", name: "Athlete" },
  { img: "Entertainers/Blogger.svg", name: "Blogger" },
  { img: "Entertainers/Vlogger.svg", name: "Vlogger" },
  // { img: "Entertainers/Actor.svg", name: "Comedian" },
  { img: "Entertainers/Dancer.svg", name: "Dancer" },
  { img: "Entertainers/Singer.svg", name: "Emcee" },
  { img: "Entertainers/Gamer.svg", name: "Gamer" },
  { img: "Entertainers/Musician.svg", name: "Musician" },
  { img: "Entertainers/Arts&Crafts.svg", name: "Painter / Crafting" },
  // { img: "Events/Theatre.svg", name: "Theater performer" },
  { img: "Entertainers/Writer.svg", name: "Writer / Poet" },
  { img: "Entertainers/Beatmaker.svg", name: "Beatmaker" },
  { img: "Affiliates/Cameraoperator.svg", name: "Cameraoperator" },
  { img: "Affiliates/Construction.svg", name: "Construction" },
  { img: "Affiliates/Teacher.svg", name: "Teacher" },
  { img: "Affiliates/stage_event.svg", name: "Stage & Event Tech" },
  {
    img: "Affiliates/Logistics_Transport.svg",
    name: "Logistics & Transport",
  },

  {
    img: "Affiliates/Instrumentbuilder.svg",
    name: "Instrumentbuilder",
  },
  { img: "Entertainers/Designer.svg", name: "Designer" },
  { img: "Entertainers/DJ.svg", name: "DJ" },
  { img: "Entertainers/Mixengineer.svg", name: "Mixengineer" },
  { img: "Entertainers/Musiccomposer.svg", name: "Musiccomposer" },
  { img: "Entertainers/Model.svg", name: "Model" },
  { img: "Entertainers/Performanceart.svg", name: "Performanceart" },
  { img: "Entertainers/Petshow.svg", name: "Petshow" },
  { img: "Entertainers/Photographer.svg", name: "Photographer" },
  { img: "Entertainers/Podcaster.svg", name: "Podcaster" },
  { img: "Entertainers/Singer.svg", name: "Singer" },
  { img: "Entertainers/Dance.svg", name: "Dance Event" },
  { img: "Events/DJ.svg", name: "DJ Event" },
  { img: "Entertainers/Game.svg", name: "Game Event" },
  { img: "Entertainers/Music.svg", name: "Music Event" },
  { img: "Entertainers/Petshow.svg", name: "Petshow Event" },
  { img: "Entertainers/Photography.svg", name: "Photography Event" },
  { img: "Entertainers/Sports.svg", name: "Sports Event" },
  { img: "Entertainers/Podcast.svg", name: "Podcast Event" },
  { img: "Entertainers/Theatre.svg", name: "Theatre Event" },
  { img: "Entertainers/Theatre.svg", name: "THouse Location" },
  { img: "Entertainers/Theatre.svg", name: "Theatre Location" },
  { img: "Entertainers/Theatre.svg", name: "Miscellaneous Location" },
  { img: "Entertainers/Theatre.svg", name: "Rehearsal Location" },
  { img: "Entertainers/Theatre.svg", name: "Music Store" },
  { img: "Entertainers/Theatre.svg", name: "Record Store" },
  {
    img: "Entertainers/Theatre.svg",
    name: "Recording Studio Location Event",
  },
];
const DropdownMenu = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    // Perform actions based on the selected option
    console.log(`Selected option: ${option}`);
    const { postId, userId } = props;

    if (option === "delete") {
      deletePost(postId)
        .then((res) => {
          toast.success("Post deleted successfully.");

          props.getAllPosts();
        })
        .catch((error) => {
          toast.error("Please try again.");
        });
    } else if (option === "notInterested") {
      addToNotInterestedPost({ postId, userId })
        .then((res) => {
          props.getAllPosts();

          toast.success("Post marked as not interested successfully.");
        })
        .catch((error) => {
          toast.error("Please try again.");
        });
    }
    // Close the dropdown after selecting an option
    setIsDropdownOpen(false);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Image/button that toggles the dropdown */}
      <img
        src={myImage}
        alt="abc"
        onClick={handleToggleDropdown}
        style={{
          marginLeft: "5px",
          color: "#fff",
          height: "15px",
          width: "15px",
          cursor: "pointer",
        }}
      />

      {/* Dropdown content */}
      {isDropdownOpen && (
        <div
          style={{
            position: "absolute",
            top: "25px",
            left: "0",
            background: "#fff",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "5px",
            color: "#000",
            width: "150px",
            fontSize: "17px",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
          }}
        >
          {!props.owner && (
            <div
              onClick={() => handleOptionClick("notInterested")}
              style={{ cursor: "pointer", padding: "8px" }}
            >
              Not Interested
            </div>
          )}

          {props.owner && (
            <div
              onClick={() => handleOptionClick("delete")}
              style={{ cursor: "pointer", padding: "8px" }}
            >
              Delete
            </div>
          )}

          {/* Add more options as needed */}
        </div>
      )}
    </div>
  );
};

const Feed = () => {
  const id = JSON.parse(localStorage.getItem("user")).id;
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [postsData, setPostsData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [userPosts, setUserPosts] = useState([]);
  const [friendsPosts, setFriendsPosts] = useState([]);
  const [favouritePosts, setFavouritePosts] = useState([]);
  const [loader, setLoader] = useState(false);
  const getAllPosts = () => {
    setLoader(true);
    getPosts(id)
      .then((response) => {
        if (response.data) {
          if (response.data.data) {
            setLoader(false);
            let res = [];
            response.data.data.forEach((p) => {
              if (p.notInterested) {
                if (!p.notInterested.includes(id)) res.push(p);
              } else res.push(p);
            });
            const temp = res.sort(
              (p1, p2) => new Date(p2.createdDate) - new Date(p1.createdDate)
            );
            setPostsData(temp);
            setSearchData(temp);
            setUserPosts(temp);
            setFriendsPosts(temp);
            setFavouritePosts(temp);
          }
        }
      })
      .catch((e) => {
        //  showToast({
        //    title: "Something went wrong",
        //    type: "error",
        //  });
      });
  };
  const getPostsByUser = () => {
    setSelectedMenu(1);

    setUserPosts(postsData.filter((p) => p.user && p.user.id === id));
    setSearchData(postsData.filter((p) => p.user && p.user.id === id));
  };
  const getPostsByFriend = async () => {
    setSelectedMenu(2);

    const userFriends = await getUserFriends_(id);

    const ans = [];
    userFriends.data.forEach((d) => {
      ans.push(...postsData.filter((p) => p.user && p.user.id === d.friendId));
    });
    setFriendsPosts(ans);
    setSearchData(ans);
  };
  const getUserFriends_ = async (id) => {
    return await getUserFriend(id);
  };
  const getUsersFavouritePosts = () => {
    // const selectedMenu = 4;
    setSelectedMenu(4);

    const tempFav = postsData.filter((p) => p.favourite.includes(id));
    setFavouritePosts(tempFav);
    setSearchData(tempFav);
  };
  useEffect(() => {
    getAllPosts();
  }, []);
  const getData = () => {
    if (selectedMenu === 0) return searchData;
    if (selectedMenu === 1) return userPosts;
    if (selectedMenu === 2) return friendsPosts;
    if (selectedMenu === 4) return favouritePosts;
  };

  const getRole = (roleName) => {
    console.log(roleName, "roleName");
    return rolesIcon.find((x) => x.name === roleName).img;
  };

  const handleFav = (postId) => {
    let flag = 0;
    postsData.find((p) => {
      if (p.favourite.includes(id) && p.id === postId) {
        flag = 1;
        return;
      }
    });
    if (flag === 0) {
      addToFav({ postId: postId, userId: id })
        .then((response) => {
          getAllPosts();
        })
        .catch((e) => {
          // this.showToast({
          //   title: "Something went wrong",
          //   type: "error",
          // });
        });
    } else {
      removeFromFav({ postId: postId, userId: id })
        .then((response) => {
          if (response.data) {
            getAllPosts();
          }
        })
        .catch((e) => {
          // this.showToast({
          //   title: "Something went wrong",
          //   type: "error",
          // });
        });
    }
  };

  const handleLike = (postId) => {
    let flag = 0;
    postsData.find((p) => {
      if (p.likes.includes(id) && p.id === postId) {
        flag = 1;
        return;
      }
    });
    if (flag === 0) {
      addToLikes({ postId: postId, userId: id })
        .then((response) => {
          getAllPosts();
        })
        .catch((e) => {
          // this.showToast({
          //   title: "Something went wrong",
          //   type: "error",
          // });
        });
    } else {
      removeFromLikes({ postId: postId, userId: id })
        .then((response) => {
          if (response.data) {
            getAllPosts();
          }
        })
        .catch((e) => {
          // this.showToast({
          //   title: "Something went wrong",
          //   type: "error",
          // });
        });
    }
  };

  const userPost = selectedMenu;
  return (
    <Container className="p-0 feed-page">
      <Row>
        <Col>
          <div>
            <Row>
              <Col sm={12}>
                <button
                  className={` ${
                    userPost === 1
                      ? "mt-lg-5 mr-sm-2 btn  active menu-btn "
                      : "mt-lg-5 mr-sm-2 btn btn-primary menu-btn"
                  }`}
                  onClick={getPostsByUser}
                >
                  My Posts
                </button>
              </Col>
              <Col sm={12}>
                <button
                  className={` ${
                    userPost === 2
                      ? "mt-lg-5 mr-sm-2 btn active menu-btn "
                      : "mt-lg-5 mr-sm-2 btn btn-primary menu-btn"
                  }`}
                  onClick={getPostsByFriend}
                >
                  Friends
                </button>
              </Col>
              <Col sm={12}>
                <button className="mt-lg-5 mr-sm-2 btn-primary btn menu-btn">
                  Subscriptions
                </button>
              </Col>
              <Col sm={12}>
                <button
                  className={` ${
                    userPost === 4
                      ? "mt-lg-5 mr-sm-2 btn active menu-btn "
                      : "mt-lg-5 mr-sm-2 btn btn-primary menu-btn"
                  }`}
                  onClick={getUsersFavouritePosts}
                >
                  Bookmarked
                </button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <Row></Row>
          {loader ? (
            <Image
              src={require("../../assets/Preloader.gif")}
              className="logo-img"
            />
          ) : (
            <Row>
             {/* <PostForm  getAllPosts={getAllPosts} />*/}
              <PostCreationBox getAllPosts={getAllPosts} />

              {getData().map((post, i) => {
                return (
                  <div className="mb-5">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#4b4b4b",
                        padding: "5px",
                      }}
                    >
                      <div>
                        {post.user.role &&
                          post.user.role.map((role, i) => {
                            return (
                              <img
                                // src={require(`../../assets/${getRole(role)}`)}
                                src={`../../assets/${getRole(role)}`}
                                alt="role"
                                height="20px"
                                width="20px"
                                title={role}
                                style={{ margin: "3px" }}
                              />
                            );
                          })}
                      </div>
                      <div>
                        Tags
                        {/* <img
                          src={require("../../assets/more.png")}
                          alt="abc"
                          style={{ marginLeft: "5px", color: "#fff",height:"15px",width:"15px" }}
                        /> */}
                       <DropdownMenu
                          postId={post.id}
                          owner={post.user.id === id}
                          userId={id}
                          getAllPosts={getAllPosts}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", padding: "5px" }}>
                      <img
                        src={require("../../assets/users.svg").default}
                        // src={post.user.profile}
                        // onError={(e) => {
                        //   e.target.onerror = null;
                        //   e.target.src =
                        //     require("../../assets/users.svg").default;
                        // }}
                        style={{ height: "80px", width: "80px" }}
                        alt="profile"
                      />
                      <div> @{post.user.username}</div>
                    </div>

                    <div> {post.user.about && post.user.about}</div>

                    <div
                      className="post-desc"
                      dangerouslySetInnerHTML={{ __html: post.description }}
                    ></div>

                    <div
                      className="post-desc"
                    >
                      {
                        post.images.map(img => {
                          console.log(img, 'attachment')
                          return (
                            <img src={img} />
                          )
                        })
                      }

                    </div>
                    <div
                      style={{
                        backgroundColor: "#131313",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ padding: "5px" }}>
                        <span
                          style={{
                            color: "#fff",
                            padding: "5px",
                            marginRight: "7px",
                          }}
                        >
                          {!post.likes.includes(id) ? (
                            <img
                              src={likesicon}
                              alt="like"
                              height="20px"
                              width="20px"
                              style={{ margin: "3px", color: "#fff" }}
                              onClick={() => handleLike(post.id)}
                            />
                          ) : (
                            <img
                              src={likesfill}
                              alt="like"
                              height="20px"
                              width="20px"
                              style={{ margin: "3px", color: "#fff" }}
                              onClick={() => handleLike(post.id)}
                            />
                          )}

                          {post.likes.length}
                        </span>

                        <span
                          style={{
                            color: "#fff",
                            padding: "5px",
                            marginRight: "7px",
                          }}
                        >
                          {!post.favourite.includes(id) ? (
                            <img
                              src={favouriteIcon}
                              alt="like"
                              height="20px"
                              width="20px"
                              style={{ margin: "3px", color: "#fff" }}
                              onClick={() => handleFav(post.id)}
                            />
                          ) : (
                            <img
                              src={favouriteFillIcon}
                              alt="like"
                              height="20px"
                              width="20px"
                              style={{ margin: "3px", color: "#fff" }}
                              onClick={() => handleFav(post.id)}
                            />
                          )}
                          {post.favourite.length}
                        </span>

                        <span
                          style={{
                            color: "#fff",
                            padding: "5px",
                            marginRight: "7px",
                          }}
                        >
                          <img
                            src={commenticon}
                            alt="like"
                            height="20px"
                            width="20px"
                            style={{ margin: "3px", color: "#fff" }}
                          />
                          {post.comment.length}
                        </span>

                        <span
                          style={{
                            color: "#fff",
                            padding: "5px",
                            marginRight: "7px",
                          }}
                        >
                          <img
                            src={reposticon}
                            alt="like"
                            height="20px"
                            width="20px"
                            style={{ margin: "3px", color: "#fff" }}
                          />
                          {post.repost.length}
                        </span>

                        <span
                          style={{
                            color: "#fff",
                            padding: "5px",
                            marginRight: "7px",
                          }}
                        >
                          <img
                            src={shareicon}
                            alt="like"
                            height="20px"
                            width="20px"
                            style={{ margin: "3px", color: "#fff" }}
                          />
                          {post.shares.length}
                        </span>
                      </div>
                      <div style={{ fontSize: "medium", padding: "5px" }}>
                        {post.updatedAt && (
                          <div style={{ color: "#fff", fontSize: "small" }}>
                            <span style={{ color: "#fff", fontSize: "medium" }}>
                              edited on{" "}
                            </span>
                            {new Date(post.updatedAt).toLocaleDateString(
                              "en-GB"
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Row>
          )}
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default Feed;
