import { createSlice,current  } from "@reduxjs/toolkit";
import {axiosInstance} from '../services';

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState: {
    statistics: [],
    isLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      console.log(current(state));
      state.isLoading = action.payload
      state.roles = []
      state.error = ''
    },
    getStatistics: (state, action) => {
      state.statistics = action.payload
      state.error = ''
      state.isLoading = false
    },
    hasError: (state, action) => {
      state.error = action.payload
      state.isLoading = false
      state.statistics = []
    }
   
  },
});

export const { getStatistics, setLoading, hasError } = statisticsSlice.actions;

export const getStatisticsData = () => async dispatch => {
   dispatch(setLoading(true));
    try {
      await axiosInstance.get('/statistics/getStatistics/')
        .then((response) => {
          dispatch(setLoading(false));
          if(response.data.code === '401')
            return dispatch(hasError(response.data.msg))

          return dispatch(getStatistics(response.data));

        })
    } catch (e) {
     return console.error(e.message);
    }
  };

export default statisticsSlice.reducer;