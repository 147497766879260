import React, { useEffect } from "react";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./forgotPassword.scss";
import { forgotPasswordData } from "../../toolkit/usersSlice";

export const ForgotPassword = () => {
  const isLoading = useSelector((state) => state.user.isLoading);
  const forgotPasswordMsg = useSelector((state) => state.user.forgotPasswordSuccessMessage);
  const forgotPasswordErr = useSelector((state) => state.user.error);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {} });

  useEffect(() => {
    if(forgotPasswordErr.length)
        alert(forgotPasswordErr)
  }, [forgotPasswordErr])

  useEffect(() => {
    if(forgotPasswordMsg.length)
        alert(forgotPasswordMsg)
  }, [forgotPasswordMsg])

  const forgotPassword = (user) => {
    dispatch(forgotPasswordData(user));
    if (!forgotPasswordErr) {
      reset();
    }
  };
  return (
    <div>
      <Container className="forgot-page">
       
        <Row>
          <Col md={12}>
            <Form.Control
              type="text"
              placeholder="Email"
              id="email"
              required
              className="box-line"
              {...register("email", {
                required: "Email is required",
                validate: {
                  matchPattern: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "Email address must be a valid address",
                },
              })}
            />
            {errors.email && (
              <span className="error">{errors.email.message}</span>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="my-4" md={12}>
            <input
              type="submit"
              value="Submit"
              className="signInBtn"
              disabled={isLoading}
              onClick={handleSubmit(forgotPassword)}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
