import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./editProfile.scss";
import { getEditProfileData, updateProfile, updateProfileData } from "../../toolkit/usersSlice";
import EditProfileComponent from "./editProfileContainer"
import { successMessage, warningMessage } from "../../toolkit/toastSlice";

export const EditProfile = () => {
  const animatedComponents = makeAnimated();
  const roles = useSelector((state) => state.roles.roles);
  const isLoading = useSelector((state) => state.user.isLoading);
  const userError = useSelector((state) => state.user.userError);
  const editUserData = useSelector((state) => state.user.editUser);
  const profileData = useSelector((state) => state.user.profileData);
  const updateProfileMessage = useSelector(
    (state) => state.user.updateProfileMessage
  );

  const [rolesData, setRolesData] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (roles.length) {
      setRolesData(
        roles.map((r) => {
          return { value: r.name, label: r.name };
        })
      );
    }
  }, [roles]);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({ defaultValues: {} });
  const history = useNavigate();

  const [coverImage, setCoverImage] = useState("");
  const pickCoverImage = (e) => {
    let input = e.target;
    let file = input.files;
    if (file && file[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCoverImage(e.target.result);
      };
      reader.readAsDataURL(file[0]);
      // this.photo = file[0];
    }
  };
  const [profile, setProfile] = useState("");
  const pickProfile = (e) => {
    let input = e.target;
    let file = input.files;
    if (file && file[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setProfile(e.target.result);
      };
      reader.readAsDataURL(file[0]);
      // this.photo = file[0];
    }
  };

  // Submit form
  const editUser = (user) => {
    if(user.role){
      user.role = user.role.map((r) => typeof r === 'object' ? r.value : r )
    }
    dispatch(
      updateProfile(JSON.parse(localStorage.getItem("user")).username, {
        ...user,
        img: coverImage,
              profile: profile || profileData.profile

      })
    );

  };

  useEffect(() => {
    if (userError) dispatch(warningMessage(userError));
  }, [userError]);

  // Get user data and fill form
  useEffect(() => {
    if (Object.keys(editUserData).length) {
      reset(editUserData);
      setCountry(editUserData.country);
      setRegion(editUserData.region);
      setProfile(editUserData.profile);
      setCoverImage(editUserData.img)
      setSelectedRoles(
        rolesData.length
          ? rolesData.filter((r) => editUserData?.role?.includes(r.value))
          : []
      );
    }
  }, [editUserData, reset]);

  useEffect(() => {
    if (updateProfileMessage === 'Profile updated successfully!') {
      dispatch(successMessage(updateProfileMessage));
      const id = JSON.parse(localStorage.getItem("user"))._id || JSON.parse(localStorage.getItem("user")).id;
      navigate(`/profile/${id}`);
    }
  }, [updateProfileMessage]);
  useEffect(() => {
    if (rolesData.length)
      dispatch(getEditProfileData(JSON.parse(localStorage.getItem("user")).id || JSON.parse(localStorage.getItem("user"))._id));
  }, [dispatch, rolesData]);

  const getPlace = () => {
    const google = window.google;
    google.maps.event.addDomListener(window, "load", initialize);

    function initialize() {
      var input = document.getElementById("autocomplete_search");

      var autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", function () {
        var place = autocomplete.getPlace();

        // place variable will have all the information you are looking for.

        // $("#lat").val(place.geometry["location"].lat());
        const lat = place.geometry["location"].lat();
        const lng = place.geometry["location"].lng();
        setValue("lat", lat);
        setValue("lng", lng);
      });
    }
    initialize();
  };
  const updateLocation = () => {
    const {lat, lng} = getValues()
    dispatch(editUser({...JSON.parse(localStorage.getItem('user')) , lat, lng}))
  }
return (
    <Container className="edit-profile-page w-75">
          <EditProfileComponent profileData={editUserData}/>

    </Container>
  )
  return (
    <Container className="edit-profile-page w-75">
      <Row className="d-flex text-start m-2">
        <Col>
          {coverImage ? (
            <Image src={coverImage} className="banner-img" />
          ) : (
            <Image
              src={require("../../assets/profile-banner.png")}
              className="banner-img"
            />
          )}
          <div className="w-100 text-end">
          <i
            className="fa fa-pencil edit-btn"
            onClick={() => {
              document.getElementById("imgUpload").click();
            }}
          />
          <Form.Control
            type="file"
            id="imgUpload"
            hidden
            required
            className="box-line"
            onChange={pickCoverImage}
          />
          </div>
        </Col>
      </Row>
      <Row className="d-flex text-start m-2">
        <Col className="text-center">
        
           {profile ? (
            <Image src={profile} className="profile-img" />
          ) : (
            <Image
            src={require("../../assets/users.svg").default}
              className="profile-img"
            />
          )}
          <div className="w-100 text-end">
          <i
            className="fa fa-pencil edit-btn"
            onClick={() => {
              document.getElementById("profileUpload").click();
            }}
          />
          <Form.Control
            type="file"
            id="profileUpload"
            hidden
            required
            className="box-line"
            onChange={pickProfile}
          />
          </div>
        </Col>
      </Row>
      <Row className="d-flex text-start m-2">
        <Col className="text-center">
          <button className="white-bordered-btn">Delete Profile</button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Container className="px-5 mx-md-5 fields-container">
          <Row className="px-md-5 justify-content-center">
            <Col sm={12} className="mb-3 px-md-5">
              {/* <Form.Control
                type="text"
                placeholder="City"
                id="city"
                required
                className="box-line"
                {...register("city", { required: true })}
              /> */}
              <div id="custom-search-input">
                <div class="input-group">
                  <input
                    id="autocomplete_search"
                    name="autocomplete_search"
                    type="text"
                    class="form-control box-line"
                    placeholder="City"
                    onChange={getPlace}
                  />
                </div>
              </div>
              <Button className="w-100 mt-2" onClick={updateLocation}>Update Location</Button>
            </Col>
            <Col md={12} className="mb-3 px-md-5 white text-start">
              Username (14 characters max)
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="Username"
                id="username"
                required
                className="box-line"
                {...register("username", { required: true })}
              />
              {errors.username && (
                <span className="error">{"Username is required"}</span>
              )}
            </Col>
            <Col sm={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="First Name"
                id="firstname"
                required
                className="box-line"
                {...register("firstname", { required: true })}
              />
              {errors.firstname && (
                <span className="error">{"Firstname is Required"}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="Last Name"
                id="lastname"
                required
                className="box-line"
                {...register("lastname", { required: true })}
              />
              {errors.lastname && (
                <span className="error">{"Lastname is required"}</span>
              )}
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={rolesData || []}
                value={selectedRoles}
                // key={selectedRoles}
                {...register("role", { required: false })}
                onChange={(e) => {
                  const permittedValues = e.map(function (record) {
                    return record;
                  });
                  setValue("role", permittedValues);
                  setSelectedRoles(permittedValues);
                }}
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                type="text"
                placeholder="Type exactly what you are"
                id="customRole"
                required
                className="box-line"
                {...register("customRole", { required: false })}
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <CountryDropdown
                value={country}
                countryValueType="short"
                onChange={(value) => {
                  setValue("country", value);
                  setCountry(value);
                }}
                className="box-line form-control"
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <RegionDropdown
                country={country}
                value={region}
                onChange={(value) => {
                  setValue("region", value);
                  setRegion(value);
                }}
                className="box-line form-control"
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <Form.Control
                as="textarea"
                placeholder="About me"
                id="about"
                required
                className="box-line"
                {...register("about", { required: false })}
              />
            </Col>
            <Col md={12} className="mb-3 px-md-5">
              <input
                // disabled={isLoading}
                type="submit"
                value="Update Profile"
                className="btn-primary btn w-100"
                onClick={handleSubmit(editUser)}
              />
            </Col>
          </Row>
          <Row>
            <Col><Button onClick={() => navigate('/map')}>Enter Later</Button></Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};
