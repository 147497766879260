import React, { useState, useEffect, useRef } from "react";
import "./PostCreationBox.scss"; // Import your CSS file
import BottomBar from "./BottomBar";
import { getProfile } from "../../toolkit/usersSlice";
import { addNewPost, addNewPostAttachment } from "../../toolkit/postSlice";
import { toast } from "react-toastify";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useSelector, useDispatch } from "react-redux";
import { Image as ImgAvatar } from "react-bootstrap";

const PostCreationBox = (props) => {
  const [text, setText] = useState("");
  const id =
    JSON.parse(localStorage.getItem("user"))._id ||
    JSON.parse(localStorage.getItem("user")).id;

  const fileRef = useRef(null);
  const gifRef = useRef(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const [postFiles, setPostFiles] = useState([]);
  const [renderImages, setRenderImages] = useState([]);
  const [severFiles, setServerFiles] = useState([]);

  const [showEmoji, setShowEmoji] = useState(false);
  const profileData = useSelector((state) => state.user.profileData);
  const [postImages, setPostImages] = useState([]);
  useEffect(() => {
    dispatch(getProfile(id));
  }, [dispatch, id]);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Handle submitting the post here, for example, you can send the 'text' to a server or perform any other action.
    if (text.length > 0) {
      addNewPost({ description: text, user: id, images: postImages })
        .then((response) => {
          setLoading(false);
          setText("");
          setSelectedEmoji("");
          setPostFiles([]);
          setRenderImages([]);
          setShowEmoji(false);
          setPostImages([])
          toast.success("Post added successfully.");
          if (props.getAllPosts) {
            props.getAllPosts();
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error("Please try again.");
        });
    } else {
      toast.error("Please add something to post.");
    }
  };

  const handleEmojiSelect = (emoji) => {
    const creationContent = `${text} ${emoji.native} `;
    setText(creationContent);
  };

  const onFileChangeCapture = async (e) => {
    const newPostFiles = postFiles;
    const file = URL.createObjectURL(e.target.files[0]);
    newPostFiles.push(file);
    renderThumbnails(newPostFiles);
    setPostFiles(newPostFiles);

    const filesForServer = severFiles;
     filesForServer.push(e.target.files[0]);
    setServerFiles(filesForServer);
    const formData = new FormData();
    console.log(severFiles, "severFiles");

    formData.append(`file`, e.target.files[0]);


    try {
      addNewPostAttachment(formData).then((res) => {
        console.log(res, "res");
        const currentPost = postImages;
        currentPost.push(res.data);
        setPostImages(currentPost)
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  function renderThumbnails(newPostFiles) {
    if (newPostFiles.length > 0) {
      const imagesRender = newPostFiles.map((item, index) => {
        return (
          <div class="thumbnail-container">
            <div class="thumbnail">
              <img src={item} alt="Thumbnail" />
            </div>
            <span
              class="close-icon"
              onClick={() => {
                if (index > -1) {
                  const updateMedia = postFiles;
                  updateMedia.slice(index, 1);
                  renderThumbnails(updateMedia);
                  setPostFiles(updateMedia);
                }
              }}
            >
              &times;
            </span>
          </div>
        );
      });
      setRenderImages(imagesRender);
    }
  }

  return (
    <div>
      <div
        className={
          loading ? "post-creation-box disabled-div" : "post-creation-box"
        }
      >
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center">
            <div className="avatar-container-parent items-center">
              <div className="avatar-container">
                {profileData?.profile !== "null" && (
                  <ImgAvatar src={profileData.profile} className="avatar" />
                )}
              </div>

              <p className="text-white text-align-center">
                @{profileData.username}
                <p className="text-white text-align-center">
                  {profileData.customRole}
                </p>
              </p>
            </div>
          </div>
          <div className="text-area-container relative flex-grow ml-4">
            <form onSubmit={handleSubmit}>
              <textarea
                className="post-textarea"
                placeholder="What's on your mind?"
                value={text}
                onChange={handleChange}
                onFocus={() => setShowEmoji(false)}
              />

              <input
                className="hide-field"
                type="file"
                name="file"
                id="input"
                accept="image/png, image/gif, image/jpeg"
                onChangeCapture={onFileChangeCapture}
                ref={fileRef}
              />

              <input
                className="hide-field"
                type="file"
                name="file"
                id="input"
                accept="image/gif"
                onChangeCapture={onFileChangeCapture}
                ref={gifRef}
              />

              {/* <button type="submit" className="post-btn">Post</button> */}
            </form>
          </div>
        </div>
        <BottomBar
          setShowEmoji={setShowEmoji}
          showEmoji={showEmoji}
          fileRef={fileRef}
          postFiles={postFiles}
          renderImages={renderImages}
          gifRef={gifRef}
          handleSubmit={handleSubmit}
        />
      </div>
      {showEmoji && (
        <div className="emoji-container">
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
    </div>
  );
};

export default PostCreationBox;
